import styled from 'styled-components'
import { DragDropZone, Recording } from '../components'
import { Typography } from '../constant/GlobalTypography'

const SelectMethod = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Module = styled.li`
  position: relative;
  width: 520px;

  &:after {
    content: "";
    position: absolute; 
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 5;  
    cursor: pointer;
  }
`
const Gap = styled.li`
  ${Typography.h5 as any};
  width: 116px;
  text-align: center;
`

const SelectUploading = (props:Props) => {

  const {selectMethod, changeOrder, currentOrder} = props

  const ChangeMethod = (method:any) => {
    selectMethod(method)
    let result  = currentOrder + 1
    changeOrder(result)
  }

  return(
    <SelectMethod>
      <Module onClick={()=>ChangeMethod('Recording')}>
        <Recording/>
      </Module>
      <Gap>OR</Gap>
      <Module onClick={()=>ChangeMethod('Files')}>
        <DragDropZone/>
      </Module>
    </SelectMethod>
  )

}

type Props = {
  selectMethod: any
  changeOrder: any
  currentOrder: number
  serviceList: any
}

export default SelectUploading;