import styled from 'styled-components'
import { Typography } from '../constant/GlobalTypography'
import { ICONS_URLS } from '../constant/Data/ImageUrl'
import { ColorTheme } from '../constant/GlobalStyles'

const QRCodeArea = styled.div`
  padding-top: 40px;
  text-align: center;
`
const ImageArea = styled.div`
  display: block;
  max-height: 240px;
`
const Label = styled.span`
  display: inline-block;
  ${Typography.caption}
  background-color: ${ColorTheme.PurpleLight};
  border-radius: 100px;
  padding: 4px 10px;
  margin-top: 40px;
`

const SelectUploading = () => {

  return(
    <QRCodeArea>
      <ImageArea>
        <img src={ICONS_URLS.QR} alt=""/>
      </ImageArea>
      <Label>QR Code for song lists</Label>
    </QRCodeArea>
  )

}



export default SelectUploading;