import styled from 'styled-components'
import { Dropdown } from '../components'
import { Typography } from '../constant/GlobalTypography'
import { DefaultInnerwrap, ColorTheme, HeightMargin } from '../constant/GlobalStyles'
import { Navigation, NavList, LAYOUT_IMAGE_URLS } from '../constant/Data'

const HeaderArea = styled.div`
  background-color: ${ColorTheme.BlackReal};
`
const HeaderInnerwrap = styled(DefaultInnerwrap)`
  display: flex;
  justify-content: space-between;
  background-color: ${ColorTheme.BlackReal};
`
const Logo = styled.a`
  width: 182px;
  height: ${HeightMargin.Header_Height};
  display: flex;
  align-items: center;
  span {
    color: ${ColorTheme.WhiteLogo};
    ${Typography.logo}
  }
  span:first-child img {
    width: 116px;
  }
`
const DropdownArea = styled.div`
  display: flex;
`

const Header = () => {
  return(

    <HeaderArea>
      <HeaderInnerwrap>
        <Logo href="/">
          <span><img src={LAYOUT_IMAGE_URLS.logo_header} alt="Logo"/></span>
          <span>Labs</span>
        </Logo>

        <DropdownArea>
          <Dropdown
            title={Navigation[0].title}
            sub={NavList}/>
          <Dropdown
            title={Navigation[1].title}
            sub={Navigation[1].sub}/>
        </DropdownArea>
      </HeaderInnerwrap>
    </HeaderArea>
  
  )
}

export default Header;

