import Header from './Header'
import Footer from './Footer'

const Layout = (props: Props) => {

  const { children } = props

  return(
      <>
        <Header/>
          { children }
        <Footer/>
      </>
  )
}

type Props = {
  children: any
}

export default Layout