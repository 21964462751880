import styled from 'styled-components'
import { ColorTheme } from '../constant/GlobalStyles'
import { Typography } from '../constant/GlobalTypography'
import { ProcessStep, ResultsCategory } from '../constant/Data'
import { PrevStopRecorder } from './Recording'

const ControlButtons = styled.div<{currentPage: string}>`
  text-align: right;
  margin-top: ${(props) =>
        props?.currentPage === ProcessStep.SelectClasses
          ? '165px'
          : props?.currentPage === ProcessStep.Results?
            '130px'
            :'125px'
          };
  margin-bottom: 80px;
`
const Button = styled.span<{ active: boolean }>`
  display: inline-block;
  ${Typography.button}
  transition: color 0.25s;
  color: ${props => props.active ? ColorTheme.BlackText : ColorTheme.GrayLight4};
  cursor: ${props => props.active ? 'pointer' : 'default'};
  &:hover {
    color: ${props => props.active ? ColorTheme.Pink : ColorTheme.GrayLight4};
  }
`
const DivisionLine = styled.hr`
  display: inline-block;
  transform:rotate(90deg);
  width: 14px;
  height: 2px;
  background-color: ${ColorTheme.GrayLight4};
  border: transparent;
  margin-left: 15px;
  margin-right: 15px;
`

const ProgressButton = (props:Props) => {

  const {
    changeOrder, 
    currentOrder, 
    lastOrder, 
    processingStep, 
    serviceList,
    setServiceList,
    LoadingState,
    setLoadingState,
    SoundWaveModule,
    setPlay,
    pageInfo,
    UploadingMethod,
    ResultsData,
  } = props

  const ServerFail = ResultsData?.status !== 200
  const ResultFail = ResultsData?.data.status !=="success"

  const plusOrder = () => {
    let result  = currentOrder + 1
    changeOrder(result)
  }

  const minusOrder = () => {
    let result  = currentOrder - 1
    changeOrder(result)
  }

  const goToFirst = () => {
    changeOrder(0)
    setServiceList([])
    setLoadingState(false)
    // console.log('goToFirst')
  }

  const SoundStop = () => {
    SoundWaveModule.pause();
    setPlay(false)
    goToFirst()
  }

  const RecordStop = () => {
    PrevStopRecorder()
    let result  = currentOrder - 1
    changeOrder(result)
  }

  return(
    <ControlButtons currentPage={processingStep[currentOrder]}>
      {
        (currentOrder === 0 || currentOrder === lastOrder || LoadingState === true)?
          <Button active={false}>PREV</Button>
          :(
            (processingStep[currentOrder] === ProcessStep.Uploading
              && UploadingMethod === 'Recording') ||
            processingStep[currentOrder] === ProcessStep.Recording)?
            <Button onClick={RecordStop} active={true}>PREV</Button>
            :<Button onClick={minusOrder} active={true}>PREV</Button>
      }
      <DivisionLine/>
      {
        currentOrder === lastOrder?
          (pageInfo.ResultsCategory === ResultsCategory.SoundGraph && !ServerFail && !ResultFail)?
            <Button onClick={SoundStop} active={true}>DONE</Button>
            :<Button onClick={goToFirst} active={true}>DONE</Button>
        :(processingStep[currentOrder] === ProcessStep.Methods ||
          processingStep[currentOrder] === ProcessStep.Uploading ||
          processingStep[currentOrder] === ProcessStep.Recording ||
          (processingStep[currentOrder] === ProcessStep.SelectClasses && serviceList.length === 0)) ?
            <Button active={false}>NEXT</Button>
          : <Button onClick={plusOrder} active={true}>NEXT</Button>
      }
    </ControlButtons>
  )

}

type Props = {
  changeOrder: any
  currentOrder: number
  lastOrder: number
  processingStep: any
  serviceList: any
  setServiceList: any
  setLoadingState: any
  SoundWaveModule: any
  setPlay: any
  pageInfo: any
  UploadingMethod: any
  ResultsData: any
  LoadingState: any
}


export default ProgressButton;