import styled from 'styled-components'
import { useState, useEffect, useRef } from 'react'
import { Typography } from '../constant/GlobalTypography'
import { ControlButton } from '../components'

const SoundItemWrap = styled.div<{ isPlaying: boolean, lastItem: boolean }>`
  position: relative; 
  width: 100%;
  height: 340px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  &:hover #control_button { 
    width: 100px;
    height: 100px;
    border-color: #DB2E8A;
    background-color: #FFF0F8;
    animation-name: ${props => props.isPlaying ? 'pulse' : 'nothing'};
    animation-duration: ${props => props.isPlaying ? '2s' : 'nothing'};
    animation-iteration-count: ${props => props.isPlaying ? 'infinite' : 'nothing'};
    @keyframes pulse {
      0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(219, 46, 138, 0.7);
      }
      70% {
        transform: scale(1);
        box-shadow: 0 0 0 30px rgba(219, 46, 138, 0);
      }
      100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(219, 46, 138, 0);
      }
    }
  }
  
  &:hover #next_icon {
    opacity: 1;
  }
  &:hover > #sub_directory_name {
    color: #131313;
  }
  &:hover #play_arrow_icon {
    color: #DB2E8A;
    font-size: 40px;
  }
  & #stop_icon {
    color: #DB2E8A;
    font-size: 40px;
  }
`

const SubDirectoryName = styled.div<{ isPlaying: boolean }>`
  height: 30%;
  ${Typography.body1}
  color: ${props => props.isPlaying ? '#131313' : '#D1D1D9'};
  text-align: center;
`

const ControlButtonsWrap = styled.div`
  position: relative;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
`


const SoundItem = (props: Props) => {
  
  const { soundItemData, lastItem, focused, setFocus } = props
  const [sourceURL, setSourceURL] = useState<string>(soundItemData.sub_directory_path + '/' + soundItemData.items[0])
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const audioRef = useRef<HTMLAudioElement | null>(null)
  const soundIdx = useRef<number>(0);

  const play = () => {
    audioRef.current?.load();
    audioRef.current?.play();
    setIsPlaying(true)
    setFocus()
  }

  const nextSound = () => {
    soundIdx.current = (soundIdx.current + 1) % soundItemData.items.length
    setSourceURL(soundItemData.sub_directory_path + '/' + soundItemData.items[soundIdx.current])
  }
 
  const pause = () => {
    if(!audioRef.current?.paused) { 
      // only move on next sound if click pause buttom or change focused to false while playing
      nextSound()
    }
    audioRef.current?.pause()
    setIsPlaying(false)
  }

  const handleOnEnded = () => {
    nextSound()
    setIsPlaying(false)
  }

  useEffect(() => {
    audioRef.current?.load();
  }, [sourceURL])

  useEffect(() => {
    if(focused === false) {
      pause()
    }
  }, [focused])

  useEffect(() => { // random pick after SoundType change
    soundIdx.current = Math.floor(Math.random() * soundItemData.items.length)
    setSourceURL(soundItemData.sub_directory_path + '/' + soundItemData.items[soundIdx.current])
    setIsPlaying(false)
  }, [soundItemData])

  useEffect(() => { // random pick after mounting
    soundIdx.current = Math.floor(Math.random() * soundItemData.items.length)
    setSourceURL(soundItemData.sub_directory_path + '/' + soundItemData.items[soundIdx.current])
  }, [])

  return (
    <SoundItemWrap isPlaying={isPlaying} lastItem={lastItem}>
      <audio src={sourceURL} ref={audioRef} onEnded={handleOnEnded}/>
      <ControlButtonsWrap>
        <ControlButton
          setPlay={play}  
          setPause={pause}
          isPlaying={isPlaying}
        />
      </ControlButtonsWrap>
      <SubDirectoryName id='sub_directory_name' isPlaying={isPlaying}>{soundItemData.sub_display_name}</SubDirectoryName>
    </SoundItemWrap>
  )
}

type Props = {
  soundItemData: any, 
  lastItem: boolean, 
  focused: boolean, 
  setFocus: () => void
}

export default SoundItem