import styled,{ createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
import { Typography } from './GlobalTypography'

export const globalStyles = createGlobalStyle`
    ${reset};
    a{
        text-decoration:none;
        color:inherit;
    }
    *{
        box-sizing:border-box;
    }
    body{
        font-size:12px;
        color: black;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
`;

export default globalStyles;


export const Z_INDEXES = {
    sideMenu: 1000,
    header: 900,
    scrollButton: 800,
    innerCont: 100,
}

export const MAX_WIDTH = {
    desktop: '1200px',
    tablet: '800px',
    mobile: '410px',
    ProcessMaxWidth: '1155px',
}

export const MobilePadding = 40
export const InnerWidth = 1400

export const HeightMargin = {
    Header_Height: '60px',
    Mobile_Padding: MobilePadding + 'px',
    Margin_Large: '140px',
    Margin_Large2: '120px',
    Uploading_Height: '250px'
}

export const BreakPoint = {
    large: (InnerWidth + MobilePadding) + 'px',
    middle: '1180px',
    small: '810px',
}

export const DefaultInnerwrap = styled.div`
    position: relative;
    width: ${BreakPoint.large};
    box-sizing: border-box;
    padding: 0 ${HeightMargin.Mobile_Padding};
    margin: 0 auto;
    @media screen and (max-width: ${BreakPoint.large}) {
        width: 100%;
        min-width: 1140px;
    }
`
export const ModuleInnerwrap = styled.div`
    width: ${MAX_WIDTH.ProcessMaxWidth};
    margin: 0 auto;
    position: relative;
    @media screen and (max-width: ${BreakPoint.large}) {
        width: 100%;
    }
`

export const ColorTheme = {
    BlackReal: '#000000',
    BlackText: '#131313',
    BlackNav: '#171717',
    WhiteReal: '#ffffff',
    WhiteOpacity: 'rgba(255, 255, 255, 0.2)',
    WhiteLogo: '#FBFCFF',
    PurpleLight: '#F1F2F9',
    PurpleDeep: '#8A9AEE',
    GrayLight: '#ACADB5',
    GrayLight2: '#EFF0F5',
    GrayLight3: '#A3A3B2',
    GrayLight4: '#C4C4C4',
    GrayLight5: '#F5F5F5',
    GrayLight6: '#B9B9B9',
    GrayLight7: '#F3F3F3',
    GrayLight8: '#F0F0F0',
    GrayLight9: '#D4D4D4',
    GrayLight10: '#ACADB5',
    GrayDark: 'rgba(0, 0, 0, 0.5)',
    GrayText: '#9EA0A8',
    GrayBackground: '#FBFBFF',
    GrayBorder: '#EBEBEB',
    Blue: '#0038FF',
    Blue2: '#1436EE',
    Red: '#EE2626',
    RedLight : 'rgba(238, 38, 38, 0.1)',
    Pink: '#DB2E8A',
    Yellow: '#FEFCEB',
    Green: '#2DC6A1',
}

export const HoverEffect = {
    Transition_Time: '0.2s',
    Transition_Effect: 'ease-in',
    Basic_Border: '2px',
    Border_Radius: '10px',
}

export const SongTitle = styled.p`
    text-align: center;
    >h3 {
    ${Typography.body5}
    padding-bottom: 5px;
    }
    span {
    ${Typography.h6}
    color: ${ColorTheme.GrayText};
    }
`