import styled from 'styled-components'
import { useState } from 'react'
import { Typography } from '../constant/GlobalTypography'
import {SOUND_DIRECTORY, SoundDirectoryPathType} from '../constant/Data/SoundUrl'
import { SoundItemsList } from '../components'

const SoundLibraryHeader = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 2px solid #F5F5F5; 
  justify-content: space-between;
`

const SoundTypeHeader = styled.div<{ dataLength: number, isActive: boolean }>`
  ${Typography.body1};
  color: ${props => props.isActive ? '#131313' : '#A3A3B2'};
  padding: 0 5px 20px;
  /* width: ${props => 100/props.dataLength}%; */
  border-bottom: 2px solid #131313;
  border-bottom-width: ${props => props.isActive ? '2px' : '0px'};
  /* margin-right: 24px; */
  /* padding-bottom: 20px; */
  &:hover {
    cursor: pointer; 
    opacity: 0.7;
  }
`

const SoundLibraryContent = () => {
  const [soundType, setSoundType] = useState(0);

  const onClickSoundType = (id: number) => {
    setSoundType(id)
  }
  
  return (
    <>
      <SoundLibraryHeader>
        {
          SOUND_DIRECTORY.map((soundDirectory:SoundDirectoryPathType, id:number) => (
            <SoundTypeHeader 
              dataLength={SOUND_DIRECTORY.length} 
              isActive={id===soundType}
              onClick={()=>onClickSoundType(id)}
            >
              {soundDirectory.display_name}
            </SoundTypeHeader>
          ))
        }
      </SoundLibraryHeader>
      <SoundItemsList soundListData={SOUND_DIRECTORY[soundType]}/>
    </> 
  )
}

export default SoundLibraryContent