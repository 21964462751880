import styled from 'styled-components'
import { ColorTheme, HeightMargin } from '../constant/GlobalStyles'
import { Typography } from '../constant/GlobalTypography'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LaunchIcon from '@mui/icons-material/Launch';

const menuSidePadding = '30px'
const DropDownArea = styled.dl`
  position: relative;
  &:last-child {
    dt {
      padding-right: 0;
    }
    dd {
      right: -${menuSidePadding};
    }
  }
  &:hover {
    > dd {
      display: block;
    }
    > dt svg {
      transform: rotate(180deg);
    }
  }
`
const Title = styled.dt`
  color: ${ColorTheme.WhiteReal};
  line-height: ${HeightMargin.Header_Height};
  text-align: left;
  > span {
    vertical-align: middle;
    ${Typography.body2 as any};
    line-height: 30px;
    font-weight: 600;
  }
  > svg {
    vertical-align: middle;
  }
  padding-right: ${menuSidePadding};
`
const ListArea = styled.dd`
  position: absolute;
  top: ${HeightMargin.Header_Height};
  right: 0;
  width: 312px;
  z-index: 100;
  background-color: ${ColorTheme.BlackNav};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 12px ${menuSidePadding};
  box-sizing: border-box;
  display: none;
  text-align: right;
`
const List = styled.ul`
  > li > a {
    ${Typography.body2 as any};
    color: ${ColorTheme.WhiteReal};
    white-space: nowrap;
    transition: all 0.25s;
  }
  > li > a:hover {
    color: ${ColorTheme.WhiteReal};
    opacity: 0.3;
  }
  > li {
    padding-bottom: 10px;
  }
`
const ExternalLink = styled.a`
  display: inline-block;
  >span {
    vertical-align: middle;
  }
  >svg {
    margin-left: 10px;
    vertical-align: middle;
  }
`

const Dropdown = (props: Props) => {

  const {title, sub} = props
  
  return(
    <DropDownArea>
      <Title><span>{title}</span><ArrowDropDownIcon/></Title>
      <ListArea>
        <List>
          {
            sub.map((item:any, index:number) => (
              item.url.charAt(0) !== '/'?
                <li key={index}><ExternalLink href={item.url} target="_blank"><span>{item.title}</span><LaunchIcon/></ExternalLink></li>
              : <li key={index}><a href={item.url}>{item.component.Contents.title}</a></li>
            ))
          }
        </List>
      </ListArea>
    </DropDownArea>
  )

}

type Props={
  title: string,
  sub: any,
}

export default Dropdown;