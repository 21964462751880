import React from 'react'
import { Link } from 'react-router-dom'

export const UnstyledLink = (props: Props) => {
  const { children, url, className } = props

  if (url.charAt(0) !== '/') {
    return (
      <a href={url} target="_blank" className={className}>
        { children }
      </a>
    )
  }
  return (
    <Link to={url} className={className}>
      { children }
    </Link>
  )
}

type Props = {
  children?: any
  url: string
  className?: string
}

export default UnstyledLink