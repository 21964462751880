import { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Typography } from '../constant/GlobalTypography'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import { SongTimeSlider } from '../components'
import { EmptySongTimeSlider } from '../components'

const borderMargin = '16px'

const SongPlayerWrap = styled.div`
  position: relative;
  min-height: 117px;
  box-shadow: 0px -3px 0px 0px #F2F2F2;
`

const SongInfoControlWrap = styled.div`
  position: relative;
  display: flex;
  margin-top: 17px;
  align-items: center;
`

const SongName = styled.div`
  ${Typography.h4}
  font-size: 16px;
  line-height: 28px;
  margin-left: ${borderMargin};
  flex-grow: 1;
`

const ControlButtons = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: ${borderMargin};
`

const SkipButton = styled.div<{ isActive: boolean}>`
  color: ${props => props.isActive ? '#131313' : '#B9BABE'};
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const PlayControlButton = styled.div`
  margin: 0px 15px;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`
 
const SongPlayerBanner = (props: Props) => {

  const { songData, skipForward, skipBackward, isFirstElem, isLastElem, isPlaying, setIsPlaying } = props
  
  const [isAudioRefLoaded, setIsAudioRefLoaded] = useState<boolean>(false)
  const audioRef = useRef<HTMLAudioElement | null>(null)

  const play = () => { // play
    audioRef.current?.play()
    setIsPlaying(true)
  }

  const pause = () => { // pause
    audioRef.current?.pause()
    setIsPlaying(false)
  }

  useEffect(() => { // load data when song change
    audioRef.current?.load()
    if(isPlaying) audioRef.current?.play()
    setIsAudioRefLoaded(false) // flag that metaData not ready for passing
  }, [songData])
 
  useEffect(() => { // update playing when clicking control
    if(isPlaying === true && audioRef.current?.paused) {
      audioRef.current?.play()
    } 
    else if(isPlaying === false && !audioRef.current?.paused) {
      audioRef.current?.pause()
    }
  }, [isPlaying])
  
  useEffect(() => { // only run after first render
    const changeAudioLoadStatus = () => {
      setIsAudioRefLoaded(true)
    }
    audioRef.current!.addEventListener('loadedmetadata', changeAudioLoadStatus)  
    return () => { // remove eventListener when unmounting
      audioRef.current!.removeEventListener('loadedmetadata', changeAudioLoadStatus)
    }
  }, [])
 
  return (
    <SongPlayerWrap>
      <audio src={songData.songUrl} ref={audioRef} onEnded={() => setIsPlaying(false)} preload="metadata"/>
      <SongInfoControlWrap>
        <SongName>{songData.songName}</SongName>
        <ControlButtons>
          <SkipButton isActive={!isFirstElem} onClick={isFirstElem? ()=>{} : skipBackward}>
            <FastRewindIcon fontSize='medium'/>
          </SkipButton>
          <PlayControlButton>
            {isPlaying ? 
              <PauseIcon fontSize='large' onClick={pause}/>
              : <PlayArrowIcon fontSize='large' onClick={play}/>
            }
          </PlayControlButton>
          <SkipButton isActive={!isLastElem} onClick={isLastElem? ()=>{} : skipForward}>
            <FastForwardIcon fontSize='medium'/>
          </SkipButton>
        </ControlButtons>
      </SongInfoControlWrap>

      {isAudioRefLoaded ? 
        <SongTimeSlider
          audioRef={audioRef}
          isPlaying={isPlaying}
        />
        : <EmptySongTimeSlider/>
      }
    </SongPlayerWrap>
  )
}

type Props = {
  songData: {
    songName: string, 
    songUrl: string, 
    songAuthor: string  
  },
  skipForward: any, 
  skipBackward: any, 
  isFirstElem: boolean, 
  isLastElem: boolean,
  isPlaying: boolean, 
  setIsPlaying: any
}    

export default SongPlayerBanner