import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { Typography } from '../constant/GlobalTypography'
import { SongItemsList, SongPlayerBanner } from '../components'
import { SONG_URL } from '../constant/Data'
import { Helmet } from "react-helmet";

const borderMargin = '16px'

const MusicListWrap = styled.div` 
  position: relative;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
` 

const SongItemsListWrap = styled.div`
  position: relative;
  flex-grow: 1;
  height: 5%;
  width: 100%;
`

const MusicListHeader = styled.h4`
  ${Typography.h4};
  height: 65.5px;
  padding: 22.5px ${borderMargin};
  padding-bottom: 16px;
`

const MusicList = () => {
  const [currentSongId, setCurrentSongId] = useState<number>(-1) // set for testing - default = -1
  const [isPlaying, setIsPlaying] = useState(false)

  const skipForward = () => {
    setCurrentSongId(currentSongId! + 1)
    setIsPlaying(true)
  }

  const skipBackward = () => {
    setCurrentSongId(currentSongId! - 1)
    setIsPlaying(true)
  }

  const changeSong = (id: number) => {
    setCurrentSongId(id)
    setIsPlaying(true)
  } 

  useEffect(() => { // fix 100vh issue on mobile browser
    const vh = window.innerHeight * 0.01;
    document.getElementById("music-list-wrap")!.style.setProperty('--vh', `${vh}px`)
    window.addEventListener('resize', () => {
      const vh = window.innerHeight * 0.01;
      document.getElementById("music-list-wrap")!.style.setProperty('--vh', `${vh}px`)
    })

    return () => {
      window.removeEventListener('resize', () => {
        const vh = window.innerHeight * 0.01;
        document.getElementById("music-list-wrap")!.style.setProperty('--vh', `${vh}px`)
      })
    }
  }, [])

  return (
    <>
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Helmet>
    <MusicListWrap id="music-list-wrap">
      <MusicListHeader>
        Music List
      </MusicListHeader>
      <SongItemsListWrap>
        <SongItemsList 
          songItemsListData={SONG_URL} 
          currentSongId={currentSongId} 
          setCurrentSongId={changeSong}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
        />
      </SongItemsListWrap>
      {(currentSongId >= 0) ? 
        <SongPlayerBanner 
          songData={SONG_URL[currentSongId]}
          skipForward={skipForward}
          skipBackward={skipBackward}
          isFirstElem={currentSongId === 0}
          isLastElem={currentSongId === (SONG_URL.length - 1)}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
        />
        : <></>
      }
    </MusicListWrap>
    </>
  )
}

type SongData = {
  songName: string, 
  songImg: string,
  songAuthor: string
}

export default MusicList 