import { useEffect, useState } from 'react'
import { 
  SelectClasses, 
  DragDropZone, 
  Recording, 
  Results,
  SelectUploading,
  Precaution,
  QRCode,
  ResultsCheck,
  ModalPopup,
  Loading } from '../components'
import { ModuleInnerwrap } from '../constant/GlobalStyles'
import { ProcessStep } from '../constant/Data'

const ProcessModule = (props: Props) => {

  const { 
    pageInfo, 
    changeOrder, 
    order, 
    setServiceList, 
    ServiceList,
    LoadingState,
    setLoadingState,
    SoundWaveModule,
    setSoundWaveModule,
    playing,
    setPlay,
    setUploadingMethod,
    UploadingMethod,
    ResultsData,
    setResultsData} = props
    
  const [Modalopen, setModalOpen] = useState(false);
  const [ErrorCode, setErrorCode] = useState(0);
  const step = pageInfo.Step.ProcessingStep[order]
  const functionName = pageInfo.FunctionName

  const RecordingModule = () => {
    return(
      <Recording
        changeOrder={changeOrder} 
        currentOrder={order}
        serviceList={ServiceList}
        functionName={functionName}
        setResultsData={setResultsData}
        setModalOpen={setModalOpen}
        setErrorCode={setErrorCode}
        setLoadingState={setLoadingState}/>
    )
  }

  const DragDropZoneModule = () => {
    return(
      <DragDropZone
        changeOrder={changeOrder} 
        currentOrder={order}
        serviceList={ServiceList}
        functionName={functionName}
        setResultsData={setResultsData}
        setModalOpen={setModalOpen}
        setErrorCode={setErrorCode}
        setLoadingState={setLoadingState}/>
    )
  }

  const DefaultLayout = (props:any) => {
    const { children, uploadingModule } = props
    return(
      <ModuleInnerwrap>
        <Precaution pageData={pageInfo.Precaution}/>
        <Loading state={LoadingState}/>
        {
          uploadingModule?
          <ModalPopup 
            openPopup={Modalopen}
            setModalOpen={setModalOpen}
            ErrorCode={ErrorCode}/>
          :<></>
        }
          {children}
      </ModuleInnerwrap>
    )
  }

  switch(step) {
    case ProcessStep.QRCode: 
      return (
        <DefaultLayout>
          <QRCode/>
        </DefaultLayout>
      )
    case ProcessStep.SelectClasses: 
      return <SelectClasses 
                setServiceList={setServiceList}
                ServiceList={ServiceList}/>
    case ProcessStep.Methods: 
      return (
        <DefaultLayout>
          <SelectUploading 
            selectMethod={setUploadingMethod} 
            changeOrder={changeOrder} 
            currentOrder={order}
            serviceList={ServiceList}
          />
        </DefaultLayout>
      )
    case ProcessStep.Uploading: 
      return (
        <DefaultLayout uploadingModule={true}>
          {
            UploadingMethod === 'Recording'?
            <RecordingModule/>
            :<DragDropZoneModule/>
          }
        </DefaultLayout>
      )
      case ProcessStep.Recording: 
        return (
          <DefaultLayout uploadingModule={true}>
            <RecordingModule/>
          </DefaultLayout>
        )
    case ProcessStep.Results: 
      return (
        <ModuleInnerwrap>
          <ResultsCheck
            pageInfo={pageInfo}
            resultsData={ResultsData}/>
          <Results 
            pageInfo={pageInfo}
            resultsData={ResultsData}
            SoundWaveModule={SoundWaveModule}
            setSoundWaveModule={setSoundWaveModule}
            playing={playing}
            setPlay={setPlay}/>
        </ModuleInnerwrap>
      )
  }

  return <></>

}

type Props = {
  pageInfo: any
  changeOrder: any
  order: number
  setServiceList: any
  ServiceList: any
  LoadingState: any
  setLoadingState: any
  SoundWaveModule: any
  setSoundWaveModule: any
  playing: any
  setPlay: any
  setUploadingMethod: any
  UploadingMethod: any
  ResultsData: any
  setResultsData: any

}

export default ProcessModule;