import styled from 'styled-components'
import Layout from '../layout/Layout'
import { DefaultInnerwrap, ColorTheme } from '../constant/GlobalStyles'
import { Typography } from '../constant/GlobalTypography'
import { MainBanner, LabsList } from '../components'
import { NavList, Header_Data } from '../constant/Data'

const MainInnerWrap = styled(DefaultInnerwrap)`
    background-color: ${ColorTheme.GrayBackground};
`

const ListTitle = styled.h1`
    ${Typography.h2}
    padding-bottom: 50px;
`
const MainBackgroundColor = styled.div`
    background-color: ${ColorTheme.GrayBackground};
`

const Main = () => {

    return(
        <MainBackgroundColor>
            <Layout>
                <MainInnerWrap>
                    <MainBanner
                        pageData={NavList[0]}/>
                    <ListTitle>{Header_Data.NavCategory[0]}</ListTitle>
                    <LabsList/>
                </MainInnerWrap>
            </Layout>
        </MainBackgroundColor>
    )
}

export default Main;