import styled from "styled-components";
import { SoundItem } from "../components";
import {
  SubDirectoryType,
  SoundDirectoryPathType,
} from "../constant/Data/SoundUrl";
import { useState } from "react";

const SoundItemListWrap = styled.ul`
  margin-top: 140px;
  margin-bottom: 180px;
  width: 100%;
  display: flex;
`;

const SoundItemWrap = styled.li<{ dataLength: number }>`
  position: relative;
  width: ${(props) => 100 / Math.min(props.dataLength, 5)}%;
`;

const SoundItemsList = (props: Props) => {
  const { soundListData } = props;

  const [focusIdx, setFocusIdx] = useState<number>(-1);

  return (
    <SoundItemListWrap>
      {soundListData.sub_directories.map(
        (subDirectoryData: SubDirectoryType, idx: number) =>
          idx <= 6 ? (
            <SoundItemWrap
              dataLength={soundListData.sub_directories.length}
              key={idx}
            >
              <SoundItem
                soundItemData={subDirectoryData}
                lastItem={
                  idx === Math.min(soundListData.sub_directories.length, 5) - 1
                }
                focused={focusIdx === idx}
                setFocus={() => setFocusIdx(idx)}
              />
            </SoundItemWrap>
          ) : (
            <></>
          )
      )}
    </SoundItemListWrap>
  );
};

type Props = {
  soundListData: SoundDirectoryPathType;
};

export default SoundItemsList;
