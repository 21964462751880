import styled from 'styled-components'
import { MAIN_IMAGE_URLS } from '../constant/Data'
import { ColorTheme, BreakPoint, HeightMargin } from '../constant/GlobalStyles'
import { Typography } from '../constant/GlobalTypography'
import UnstyledLink from './UnstyledLink'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const MainBannerInnerwrap = styled.div`
  background-color: ${ColorTheme.GrayBackground};
  margin: 0 auto;
  padding: ${HeightMargin.Margin_Large2} 0 ${HeightMargin.Margin_Large};
`
const MainLink = styled(UnstyledLink)`
  font-size: 0;
  display: block;
  &:hover {
    .buttonText {
      opacity: 1;
    }
  }
`
const BackgroundArea = styled.div`
  width: 100%;
  height: 500px;
  background: url(${MAIN_IMAGE_URLS.banner}) no-repeat center;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 10px;
`
const Innerwrap = styled.div`
  width: 85%;
  height: 73.6%;
  margin: 0 auto;
  display: flex;
  @media screen and (max-width: ${BreakPoint.small}) {
    display: block;
    height: auto;
  }
`
const Title = styled.h1`
  width: 50%;
  ${Typography.h2}
  color: ${ColorTheme.WhiteReal};
  display: flex;
  align-items: center;
  @media screen and (max-width: ${BreakPoint.small}) {
    display: block;
    width: 100%;
  }
`
const ContentsArea = styled.div`
  position: relative;
  width: 50%;
  color: ${ColorTheme.WhiteReal};
  border-left: 1px solid ${ColorTheme.WhiteOpacity};
  display: flex;
  align-items: center;
  justify-content: end;
  -webkit-justify-content: flex-end;
  @media screen and (max-width: ${BreakPoint.small}) {
    display: block;
    width: 100%;
    border-left: 0px solid transparent;
    justify-content: start;
  }
`
const ButtonText = styled.p`
  text-transform: uppercase;
  color: ${ColorTheme.WhiteReal};
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
  -webkit-transition: opacity 0.15s ease-in-out;
  -moz-transition: opacity 0.15s ease-in-out;
  -ms-transition: opacity 0.15s ease-in-out;
  -o-transition: opacity 0.15s ease-in-out;

  > span {
    padding-right: 10px;
    ${Typography.button as any}
    vertical-align: middle;
  }
  > svg {
    vertical-align: middle;
  }
`
const Contents = styled.div`
  display: inline-block;
  width: 87%;
`
const Description = styled.p`
  ${Typography.body2}
`
const TagArea = styled.div`
  padding-top: 35px;
`
const Category = styled.p`
  display: inline-block;
  ${Typography.body3}
  padding-right: 10px;
`
const Tag = styled.p`
  display: inline-block;
  ${Typography.caption}
  color: ${ColorTheme.PurpleLight};
  padding: 4px 10px;
  border: 1px solid transparent;
  border-radius: 100px;
  background-color: ${ColorTheme.WhiteOpacity};
`

const MainBanner = (props: Props) => {

  const {pageData} = props

  return(
    <MainBannerInnerwrap>
      <MainLink url={pageData.url}>
        <BackgroundArea>
          <Innerwrap>
            <Title>{pageData.component.Contents.title}</Title>
            <ContentsArea>
              <Contents>
                <Description>{pageData.component.Contents.shortDescription}</Description>
                <TagArea>
                  <Category>{pageData.component.Contents.category}</Category>
                    {
                      pageData.component.Contents.isWebDemo ?
                        <Tag>WEB DEMO</Tag>
                      : <></>
                    }
                </TagArea>
                <ButtonText className="buttonText"><span>{pageData.component.Contents.buttonText}</span><ArrowForwardIcon/></ButtonText>
              </Contents>
            </ContentsArea>
          </Innerwrap>
        </BackgroundArea>
      </MainLink>
    </MainBannerInnerwrap>
  )
}

type Props = {
  pageData: any
}

export default MainBanner