import styled from 'styled-components'
import { ColorTheme, BreakPoint } from '../constant/GlobalStyles'
import { Typography } from '../constant/GlobalTypography'
import { MAIN_IMAGE_URLS, NavList } from '../constant/Data'

const Box_Padding_Side = '48px'
const Box_Padding_Bottom = '40px'
const Box_Transition_Time = '0.2s'
const Box_Effect = 'ease-in'
const Box_Basic_Border = '2px'
const Box_Top_Border = '10px'
const Box_Border_Radius = '10px'
const Box_Margin_Bottom = '30px'
const Box_Width_Medium = '48%'

const ExperimentsList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1.7vw;
  padding-bottom: 150px;
  @media screen and (max-width: ${BreakPoint.middle}) {
    grid-template-columns: repeat(2, 1fr);
  }
`
const ListItem = styled.li`
  margin-bottom: ${Box_Margin_Bottom};
  width: 100%;
  transition: transform ${Box_Transition_Time} ${Box_Effect};
  filter: drop-shadow(0px 0px 20px rgba(161, 160, 174, 0.1));
  
  &:hover {
    transform: translateY(-5px);
  }
`
const BlankItem = styled.div`
  border-radius: ${Box_Border_Radius};
  margin-bottom: ${Box_Margin_Bottom};
  width: 100%;
  padding-left: ${Box_Padding_Side};
  border-top: 0 solid transparent !important;
  background: url(${MAIN_IMAGE_URLS.commingSoon}) no-repeat 0 0;
  display: flex;
  align-items: center;
  @media screen and (max-width: ${BreakPoint.middle}) {
    width: ${Box_Width_Medium};
    display: none;
  }
  @media screen and (max-width: ${BreakPoint.small}) {
    display: none;
  }
`
const Message = styled.h3`
  ${Typography.h3};
  color: #fff;
`

const ListItemInner = styled.a`
  position: relative;
  display: block;
  height: 340px;
  box-sizing: border-box;
  padding: 60px ${Box_Padding_Side} ${Box_Padding_Bottom};
  background-color: ${ColorTheme.WhiteReal};
  background-clip: padding-box;
  border-top: solid ${Box_Top_Border} ${ColorTheme.PurpleLight};
  border-left: solid ${Box_Basic_Border} white;
  border-right: solid ${Box_Basic_Border} white;
  border-bottom: solid ${Box_Basic_Border} white;
  border-radius: ${Box_Border_Radius};
  transition: all ${Box_Transition_Time} ${Box_Effect};
  &:hover {
    border-color: transparent;
  }
  &:hover:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -${Box_Top_Border} -${Box_Basic_Border} -${Box_Basic_Border}; 
    border-radius: inherit;
    background: linear-gradient(to right, #DB2E8A, #1436EE);
	}
`
const Title = styled.h3`
  ${Typography.h3}
`
const Description = styled.p`
  padding: 30px 0 60px;
  ${Typography.body2}
  color: ${ColorTheme.GrayLight};
`
const TagArea = styled.ul`
  width: calc(100% - ${Box_Padding_Side}*2);
  position: absolute;
  bottom: ${Box_Padding_Bottom};
  left: 50%;
  margin-left: calc((100% - ${Box_Padding_Side}*2)/2*-1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${ColorTheme.GrayLight2};
  padding: 30px 0 0;
`
const Category = styled.li`
  ${Typography.body3};
`
const Tag = styled.li`
  display: inline-block;
  ${Typography.caption}
  color: ${ColorTheme.PurpleDeep};
  padding: 4px 10px;
  border: 1px solid transparent;
  border-radius: 100px;
  background-color: ${ColorTheme.PurpleLight};
`

const LabsList = () => {
  return(
    <ExperimentsList>
      {
        NavList.slice(1).map((item: any, index: number) => (
            <ListItem key={index}>
              <ListItemInner href={item.url}>
                <Title>{item.component.Contents.title}</Title>
                <Description>{item.component.Contents.shortDescription}</Description>
                <TagArea>
                    <Category>{item.component.Contents.category}</Category>
                    {
                      item.component.Contents.isWebDemo?
                      <Tag>WEB DEMO</Tag>
                      : <></>  
                    }              
                </TagArea>
              </ListItemInner>
              </ListItem>
        ))
      }
      {
        (NavList.length) % 3 === 2 ?
          <BlankItem>
            <Message>
              New Experiment<br/>
              Coming Soon!
            </Message>
            </BlankItem>
          :<></>
      }
    </ExperimentsList>
  )
}

export default LabsList