import styled from 'styled-components'
import { ColorTheme, HeightMargin } from '../constant/GlobalStyles'
import { Typography } from '../constant/GlobalTypography'

const LabMainBannerWrap = styled.div`
	margin-top: 140px;
	margin-bottom: 60px;
` 

const CategoryName = styled.h5`
	${Typography.h5}
	color: ${ColorTheme.GrayLight};
`

const LabName = styled.h2`
	${Typography.h2}
`

const OptionButton = styled.a<{ isOpenDemo: boolean }>`
	${Typography.button};
	margin: ${HeightMargin.Header_Height}  25px 0px auto;
	display: inline-block;
	padding: 11px 40px;
	color: ${ props => props.isOpenDemo ? ColorTheme.BlackText : ColorTheme.GrayLight};
	border-radius: 100px;
	border: 2px solid transparent;
	border-color: ${ props => props.isOpenDemo ? ColorTheme.BlackReal : ColorTheme.GrayLight};
	background-color: ${ColorTheme.WhiteOpacity};
	transition: 0.25s all;
	&:hover {
		cursor: pointer; 
		border-color: ${ props => props.isOpenDemo ? ColorTheme.BlackReal : ColorTheme.GrayDark};
		color: ${ props => props.isOpenDemo ? ColorTheme.BlackText : ColorTheme.GrayDark};
	}
`

const SeparateLine = styled.hr`
	border: 1px solid #F5F5F5;
	background-color: #F5F5F5;
	border-radius: 100px;
`

const Description = styled.h6`
	${Typography.h6};
	margin: 80px auto;

	> a {
		color: ${ColorTheme.Blue};
		text-decoration: underline;
	}
`

const SubPageName = styled.h3`
	${Typography.h3}
	color: #A3A3B2;
	margin-top: 80px;
`

const SubDescription = styled.div`
	${Typography.body2}
`

const SubTopArea = (props: Props) => {
	
	const { isOpenDemo, pageData } = props

	const subPageName = isOpenDemo ? 'Demo Guide' : 'Sound Library' 

	const subDescription = isOpenDemo ? 'Follow the steps to try out the demo.' : 'You can listen to the sounds that Cochl.Sense can understand.'

	return (
		<LabMainBannerWrap>
			<CategoryName>{pageData.category}</CategoryName>
			<LabName>{pageData.title}</LabName>
			{(pageData.title === 'Sound event detection')?
				<>
					<OptionButton 
						isOpenDemo={isOpenDemo}
						href="/sound-event-detection"
					>DEMO TRYOUT</OptionButton>
					<OptionButton 
						isOpenDemo={!isOpenDemo}
						href="/sound-library"
					>SOUND LIBRARY</OptionButton>
				</>
				:<></>
			}	
			<Description
				dangerouslySetInnerHTML={{
					__html: pageData.Description,
			}}>
			</Description>
			<SeparateLine/>
			<SubPageName>{subPageName}</SubPageName>
			<SubDescription>{subDescription}</SubDescription>
		</LabMainBannerWrap>
	)
}

type Props = {
		isOpenDemo: any,
    pageData: any
}

export default SubTopArea
