export const FontFamily = {
    title: 'Roboto, sans-serfif',
    contents: 'IBM Plex Sans, sans-serif',
}

export const BasicTypography = {
    fontFamily: FontFamily.title,
    lineHeight: 1.6,
}

export const Typography = {
    h1: {
        fontFamily: FontFamily.title,
        fontWeight: 500,
        fontSize: '50px',
        lineHeight: '65px',
    },  
    h2: {
        fontFamily: FontFamily.contents,
        fontWeight: 600,
        fontSize: '40px',
        lineHeight: '50px',
    },
    h3: {
        fontFamily: FontFamily.contents,
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '31.2px',
    },
    h4: {
        fontFamily: FontFamily.contents,
        fontWeight: 600,
        fontSize: '22px',
        lineHeight: '28.6px',
    },
    h5: {
        fontFamily: FontFamily.contents,
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '30px',
    },  
    h6: {
        fontFamily: FontFamily.contents,
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '28px',
    },
    body1: {
        fontFamily: FontFamily.contents,
        fontWeight: 500,
        fontSize: '17px',
        lineHeight: '22.1px',
    },
    body2: {
        fontFamily: FontFamily.contents,
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '26px',
    },
    body3: {
        fontFamily: FontFamily.contents,
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '18.2px'
    },
    body5: {
        fontFamily: FontFamily.contents,
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '1.2',
    },
    caption: {
        fontFamily: FontFamily.contents,
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '15.6px',
    },
    button: {
        fontFamily: FontFamily.contents,
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '23.4px',
    },
    logo: {
        fontFamily: FontFamily.title,
        fontWeight: 300,
        fontSize: '30px',
        lineHeight: '35.16px'
    },
}