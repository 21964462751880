import styled from 'styled-components'
import { ColorTheme } from '../constant/GlobalStyles'
import { Typography } from '../constant/GlobalTypography'
import { ModuleInnerwrap } from '../constant/GlobalStyles'

const ResultInnerwrap = styled(ModuleInnerwrap)`
  display: flex;
  justify-content: center;
`
const ContentsArea = styled.ul`
  display: flex;
`
const Info = styled.li`
  display: block;
  > ul {
    li {
      ${Typography.caption};
      color: ${ColorTheme.GrayText};
      padding-right: 20px;
      border-right: 1px solid ${ColorTheme.PurpleLight};
      margin-bottom: 30px;
    }
    li:first-child {
      height: 35px;
    }
    li:nth-child(2) {
      height: 260px;
    }
    li:last-child {
      height: 150px;
    }
  }
`
const ResultsData = styled.li``
const Tempo = styled.p`
  padding-left: 100px;
  ${Typography.h3}
`

const SoundAnalysis = (props:Props) => {

  const {resultsData} = props

  return(
    <>
    <ResultInnerwrap>
      <ContentsArea>
        <Info>
          <ul>
            <li>TEMPO</li>
            <li>MOOD</li>
            <li>GENRE</li>
          </ul>
        </Info>
        <ResultsData>
          <Tempo>{resultsData.data.result.tempo} bpm</Tempo>
          <img src={resultsData.data.result.img_path} alt=""/>
        </ResultsData>
      </ContentsArea>
    </ResultInnerwrap>

    </>
  )

}

type Props = {
  resultsData:any
}


export default SoundAnalysis;