const CheckFileSize = (file:any) => {
  const File_MB = (file.size / 1024 / 1024);
  if(File_MB > 3) {
    // console.log('This file is over the 3MB')
    return false;
  } else {
      // console.log('you pass the size rule');
      return true;
    }
}

const CheckExtension = (file:any) => {
  const Ext = file.name.split('.').pop().toLowerCase()
  const availableExt = ['wav', 'm4a', 'mp3', 'mp4', 'flac']
  if(availableExt.indexOf(Ext) !== -1) {
    // console.log('This extension is good')
    return true;
  } else {
    // console.log('This extension is not good')
    return false;
  }
}


const GoToServer = async(functionName:any, formData:any) => {

  let ResponseData:any
  try {
    ResponseData = await functionName(formData);
  } catch {
    // console.log("hello, It's failed!")
    ResponseData = {
      status: "netdead",
      data: {
        status: "netdead", 
      }
    }
  }

  return ResponseData
  
}

const SelectedClass = (formData:any, selected_classes:any) => {
  // console.log('It has selected classes')
  selected_classes.map((item:any) => {
    formData.append("selected_class", item);
  })
  return formData
}

export const FileUploading = (
    functionName:any,
    file:any, 
    selected_classes:any = null
) => {

  let resultData: any

    if(CheckExtension(file)) {
      if(CheckFileSize(file)) {
        let formData = new FormData()
        if(selected_classes.length !== 0) {
          SelectedClass(formData, selected_classes);
        }
        formData.append("file", file);
        resultData = GoToServer(functionName,formData);
      } else {
        resultData = 0;
        // console.log('please check the file size!')
      }
    } else {
      resultData = 1;
      // console.log('please check the file extension!')
    }
  
  return resultData;
}

export const RecordUploading = (
  functionName:any,
  audioBlob:any, 
  wavfile:any,
  selected_classes:any = null, 
) => {

  let resultData: any

    if(CheckFileSize(audioBlob)) {
      let formData = new FormData()
      if(selected_classes.length !== 0) {
        SelectedClass(formData, selected_classes);
      }
      formData.append("file", audioBlob, wavfile);
      resultData = GoToServer(functionName, formData);
    } else {
      resultData = 0;
      // console.log('please check the file size!')
    }
  
  return resultData;
}


