import { Category, ProcessStep, ResultsCategory, EventCustom } from ".";

export const SoundEventDetection = {
  Classes: [
    {
      category: "Emergency Detection",
      sounds: [
        ["Civil defense siren", "Civil_defense_siren"],
        ["Emergency vehicle siren", "Emergency_vehicle_siren"],
        ["Fire smoke alarm", "Fire_smoke_alarm"],
        ["Glass break", "Glass_break"],
        ["Gunshot", "Gunshot"],
        ["Scream", "Scream"],
        ["Yell", "Yell"],
      ],
      isAble: true,
    },
    {
      category: "Human Interaction",
      sounds: [
        ["Clap", "Clap"],
        ["Finger snap", "Finger_snap"],
        ["Knock", "Knock"],
        ["Throat clear", "Throat_clear"],
        ["Tongue click", "Tongue_click"],
        ["Whistle", "Whistle"],
      ],
      isAble: true,
    },
    {
      category: "Human Status",
      sounds: [
        ["Cough", ""],
        ["Hiccup", ""],
        ["Laughter", ""],
        ["Sigh", ""],
        ["Sneeze", ""],
        ["Snore", ""],
      ],
      isAble: false,
    },
    {
      category: "Home Context",
      sounds: [
        ["Appliance alarm", ""],
        ["Baby cry", ""],
        ["Cat meow", ""],
        ["Dog bark", ""],
        ["Doorbell", ""],
        ["Water run", ""],
      ],
      isAble: false,
    },
    {
      category: "Human Voice",
      sounds: [
        ["Female speech", ""],
        ["Humming", ""],
        ["Male speech", ""],
        ["Whisper", ""],
      ],
      isAble: false,
    },
    {
      category: "Traffic",
      sounds: [
        ["Car horn", ""],
        ["Loud engine acceleration", ""],
        ["Tire squeal", ""],
      ],
      isAble: false,
    },
    {
      category: "Etc.",
      sounds: [
        ["Applause", ""],
        ["Crowd", ""],
        ["Guitar", ""],
        ["Piano", ""],
        ["Thud", ""],
      ],
      isAble: false,
    },
  ],
  Contents: {
    title: "Sound event detection",
    shortDescription:
      "Let your device hear what's happening around you. The main feature of Cochl.Sense is sound event detection, and you can easily try the web demo here with a few clicks on your laptop or mobile phone.",
    Description:
      "Let your device hear what's happening around you. The main feature of Cochl.Sense is sound event detection, and you can easily try the web demo here with a few clicks on your laptop or mobile phone.",
    category: Category.cochlSense,
    isWebDemo: true,
    buttonText: "Try out this demo",
  },
  Step: {
    ProcessingStep: [
      ProcessStep.SelectClasses,
      ProcessStep.Methods,
      ProcessStep.Uploading,
      ProcessStep.Results,
    ],
    StepGuide: [
      "Select the target sounds.",
      "Record or upload audio to analyze.",
      "File submitted, wait for the result.",
    ],
    StepPoint: [1, 3],
  },
  Precaution: [
    "Minimum length of sound is <b>5 seconds</b>.",
    "Only <b>sound extention files(wav, m4a, mp3, mp4, flac)</b> are available for uploading.",
    "Please upload files <b>under 3MB</b>.",
  ],
  FunctionName: EventCustom,
  ResultsCategory: ResultsCategory.SoundGraph,
};

export default SoundEventDetection;
