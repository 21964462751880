import styled from 'styled-components'
import { SongItem } from '../components'

const SongItemsListWrap = styled.ul`
  position: relative;
  max-height: 100%;
  overflow: scroll;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none; 
`


const SongItemsList = (props: Props) => {
  
  const { songItemsListData, currentSongId, setCurrentSongId, isPlaying, setIsPlaying } = props

  return (
      <SongItemsListWrap>
        {
          songItemsListData.map((songItem: SongData, idx: number) => (
            <SongItem 
              key={idx}
              songData={songItem} 
              songId={idx} 
              isPlaying={idx===currentSongId && isPlaying} 
              playSong={() => {
                setCurrentSongId(idx)
              }}
              setIsPlaying={setIsPlaying}
            />
          ))
        }
      </SongItemsListWrap>
  )
}

type Props = {
  songItemsListData: SongData[], 
  currentSongId: number,
  setCurrentSongId: any, 
  isPlaying: boolean, 
  setIsPlaying: any
}

type SongData = {
  songName: string, 
  songUrl: string, 
  songAuthor: string
}

export default SongItemsList