import { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { DefaultInnerwrap } from '../constant/GlobalStyles'
import { 
  SubTopArea, 
  ProgressButton,
  ProcessModule,
  StepGuide
} from '../components'

const CochlDemo = (props:Props) => {
  const {currentModule} = props

  // order check
  const [Order, setOrder] = useState<any>(0)
  const StepLength = currentModule.Step.ProcessingStep.length - 1

  // check box
  const [ServiceList, setServiceList] = useState<string[]>([]);

  //Loading page
  const [LoadingState, setLoadingState] = useState(false);

  // wavesurfer and soundplayer
  const [SoundWaveModule, setSoundWaveModule] = useState<any>();
  const [playing, setPlay] = useState(false);

  // recording
  const [UploadingMethod, setUploadingMethod] = useState<string>()

  // results Data
  const [ResultsData, setResultsData] = useState<any>()

  return(                 
    <>
      <Layout>
        <DefaultInnerwrap>
          <SubTopArea 
            isOpenDemo={true} 
            pageData={currentModule.Contents}
          />

          <StepGuide 
            pageInfo={currentModule}
            currentOrder={Order}/>

          <ProcessModule 
            pageInfo={currentModule} 
            changeOrder={setOrder} 
            order={Order}
            ServiceList={ServiceList}
            setServiceList={setServiceList}
            LoadingState={LoadingState}
            setLoadingState={setLoadingState}
            SoundWaveModule={SoundWaveModule}
            setSoundWaveModule={setSoundWaveModule}
            playing={playing}
            setPlay={setPlay}
            UploadingMethod={UploadingMethod}
            setUploadingMethod={setUploadingMethod}
            ResultsData={ResultsData}
            setResultsData={setResultsData}
            />

          <ProgressButton 
            pageInfo={currentModule} 
            changeOrder={setOrder} 
            currentOrder={Order} 
            lastOrder={StepLength} 
            processingStep={currentModule.Step.ProcessingStep}
            serviceList={ServiceList}
            setServiceList={setServiceList}
            LoadingState={LoadingState}
            setLoadingState={setLoadingState}
            SoundWaveModule={SoundWaveModule}
            setPlay={setPlay}
            UploadingMethod={UploadingMethod}
            ResultsData={ResultsData}
          />

        </DefaultInnerwrap>
      </Layout>
    </>
  )
}

type Props ={
  currentModule: any
}
export default CochlDemo;