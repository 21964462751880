import {Category,ProcessStep, ResultsCategory, MusicContents} from '.'

export const MusicAnalysis = {
    Contents: {
        title: 'Music content analysis',
        shortDescription: 'Automatically get the music genre, mood, and tempo information of the music you upload.',
        Description: 'When you listen to the music, you can easily tell if this song is hip-hop, rock, or classic. You can also tell the mood of the song by how fast and strong the beat is. This is because music contains various contents(=information) that can be analyzed by the audio itself. Such contents can be used for automatic music clustering and organization, allowing listeners to experience music discovery more deeply. With our music content analysis demo, you can automatically get the music genre, mood and tempo information of the music you upload.',
        category: Category.cochlMusic,
        isWebDemo: true,
    },
    Step: {
        ProcessingStep: [
            ProcessStep.Uploading, 
            ProcessStep.Results
        ],
        StepGuide: ['Upload a music file you want to analyze.','File submitted, wait for the result.'],
        StepPoint: [1],
    },
    Precaution : ['Only <b>sound extention files(wav, m4a, mp3, mp4, flac)</b> are available for uploading.','Please upload files <b>under 3MB</b>.'],
    FunctionName: MusicContents,
    ResultsCategory: ResultsCategory.SoundAnalysis
}

export default MusicAnalysis