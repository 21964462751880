import { LAYOUT_IMAGE_URLS } from "./ImageUrl";
import {
  SoundEventDetection,
  MusicAnalysis,
  MusicIdentification,
  MusicRecommendation,
  SpeakerIdentification,
} from ".";
const { REACT_APP_OFFICIAL_WEB_URL: officialWebURL } = process.env;

export const Header_Data = {
  title: "Labs",
  NavCategory: ["Experiments", "Product"],
};

export const MainLayout_Data = {
  listTitle: Header_Data.NavCategory[0],
  defaultButtonText: "WEB DEMO",
};

const currentYear = new Date().getFullYear();

export const Footer_Data = {
  copyright: "© " + currentYear + " Cochl, Inc.",
  policy: [
    {
      title: "Terms and Conditions",
      url: `${officialWebURL}/terms/`,
    },
    {
      title: "Privacy Policy",
      url: `${officialWebURL}/privacy-policy/`,
    },
  ],
};

export const Navigation = [
  {
    title: "Experiments",
  },
  {
    title: "Product",
    sub: [
      {
        title: "Cochl.Sense",
        url: `${officialWebURL}/product/`,
      },
    ],
  },
];

export const Terms_Data = {
  title: "Terms and Conditions",
  date: "Last modified: Dec 1, 2021",
  description:
    "Thank you for using Cochl.Sense API and SDK. Please read these Terms and Conditions carefully before using our service.",
  contents: [
    {
      title: "A. Definitions",
      contents: [
        'We refer to the terms below, terms within everything we offer, including API, SDK, documentation, models, functions, software, technical support, updates and any guidelines from Our services as the "Terms".',
        "These Terms and Conditions govern the use of and access to “Service” which includes Cochl.Sense API and SDK (“Cochl.Sense”) and the website made available to Users at https://dashboard.cochl.ai(“this website” or the “Site”).",
        "“You” or “User” means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.",
        'Under the Terms, “Cochl” (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Cochl, with office at 4th floor, 6 Bongeunsa-ro 112-gil, Gangnam-gu, Seoul 06170, Republic of Korea',
      ],
    },
    {
      title: "B. Acknowledgement",
      contents: [
        "These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all Users regarding the use of the Service.",
        "Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, Users and others who access or use the Service.",
        "By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.",
        "You represent that you are over the legal age. The Company does not permit those under the legal age to use the Service.",
        "Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.",
      ],
    },
    {
      title: "C. Termination",
      contents: [
        "You are free to stop using Our Service at any time. We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if:",
        "- You breach these Terms and Conditions,",
        "- You are using the Service in a manner that would cause a real risk of harm or loss to us or other uses, or",
        "- You haven’t accessed Our Service for 12 consecutive months and You do not have credits in your account. Upon termination, Your right to use the Service will cease immediately.",
      ],
    },
    {
      title: "D. Limitation of liability",
      contents: [
        "Cochl guarantees the highest quality of its operations to ensure accessibility and continuity of offered Services in accordance with their use and purpose.",
        "Cochl does not guarantee compatibility of offered Services with other producers’ software. You shall bear responsibility for the choice and consequences following from the use of other software including its applicability to the User’s objectives.",
        "Cochl shall bear no liability in particular for: ",
        "- all negative consequences being the result of force majeure,",
        "- unlawful and inconsistent with these Terms and Conditions usage of Services,",
        "- disturbances in accessibility of offered Services not caused by Cochl,",
        "- damage that may result from imperfect performance or unpredictable behavior of the Service,",
        "- damages following from the User or the impossibility to use offered Services including damage actually suffered, the loss of expected profits/benefits , data loss , damage or computer crash , the costs of substitute equipment and software , shut-down , company reputation infringement.",
      ],
    },
    {
      title: "E. Registration",
      contents: [
        "You may be required to sign up for an account and select the account information (such as identification or contact details) and you are responsible for all activity on your account. Please be careful with protecting your account password and contact us if You encounter any issues about your account as soon as possible. Any registration information you give to Cochl will always be accurate and up to date and inform us about any updates. Cochl will not be liable for any losses caused by any unauthorized use of your account.",
      ],
    },
    {
      title: "F. Compliance with law, third party rights",
      contents: [
        "You will comply with all applicable law, regulation, and third party rights (including without limitation any right of privacy or intellectual property rights, the import or export of data or software, privacy, and local laws). You will not use Cochl products to encourage or promote illegal conducts or violation of third party rights.",
      ],
    },
    {
      title: "G. Intellectual property rights",
      contents: [
        "You will comply with all applicable law, regulation, and third party rights (including without limitation any right of privacy or intellectual property rights, the import or export of data or software, privacy, and local laws). You will not use Cochl products to encourage or promote illegal conducts or violation of third party rights.",
        "Intellectual property rights of audio data",
        "In particular, the Users acknowledge and consent that the audio data which the Users provide will be used by Cochl to develop and improve its machine listening technology. Cochl owns all data, improvements of Cochl services, and any associated intellectual property, that result from use of Cochl.Sense products and you consent to share all data with affiliates, contractors of Cochl who are bound by non-disclosure agreements.",
      ],
    },
    {
      title: "H. Notification of changes",
      contents: [
        "We reserve the right, at Our sole discretion, to modify or replace these terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days’ notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.",
        "By continuing to access or use Our ervice after those revisions become effective, You agree to be bound by the revised Terms. If You do not agree to the new Terms, in whole or in part, please stop using the website and the service.",
      ],
    },
    {
      title: "I. Contact us",
      contents: [
        "If You have any questions about these Terms and Conditions, You can contact us by email: support@cochl.ai",
      ],
    },
  ],
};

export const Policy_Data = {
  title: "Privacy Policy",
  date: "Last modified: Dec 1, 2021",
  description:
    "Personal data of users provided through using this website and Cochl.Sense API and SDK is processed in accordance with the following policy.",
  contents: [
    {
      title: "A. Responsibility",
      contents: [
        "The personal data provided will be stored in the Google Cloud or Amazon Web Services (AWS) under the responsibility of Cochl.",
      ],
    },
    {
      title: "B. Purposes",
      contents: [
        "The data is processed for the general purpose of carrying out research development and innovation studies works or projects. In particular, but without limitation, the data is processed for the purpose of providing and managing our Service, communicating with the users regarding activities of the Service, managing user accounts, putting users in contact with each other, providing notices, newsletters and other electronic communications, and for any administrative and legal / judicial purposes.",
      ],
    },
    {
      title: "C. Collection",
      contents: [
        "We collect personal data on creating user accounts and/or via contact forms, including name, surname, email address, password, company, and job title. Users must provide and maintain true and accurate personal profile data. Users must NOT upload any sensitive data regarding racial origin, trade union membership, religion, ideology and sexual life, the user’s or third party’s health, or relative to the commission of criminal offenses or proceedings and associated penalties or fines.",
      ],
    },
    {
      title: "D. Disclosure",
      contents: [
        "The data is kept confidential and not communicated to anyone. However, these data may be disclosed with your previous consent to entities that collaborate in the development of research activity and to third parties when such a disclosure is authorized by law.",
      ],
    },
    {
      title: "E. Confidentiality",
      contents: [
        "Technical and organizational measures have been adopted to preserve and protect users’ personal information from unauthorized use or access and from being altered, lost or misused, taking into account the technological state of art, the features of the information stored and the risks to which information is exposed.",
      ],
    },
    {
      title: "F. User rights",
      contents: [
        "Rights to access, correct, cancel, or object to data in these files may be exercised by writing email to support@cochl.ai ",
      ],
    },
    {
      title: "G. Consent",
      contents: [
        "By registering as user or sending a contact form, users expressly agree to this policy, including the sending of electronic communications.",
      ],
    },
  ],
};

export const SNS = [
  {
    alt: "medium",
    img: LAYOUT_IMAGE_URLS.sns.medium,
    url: "https://medium.com/cochl/",
  },
  {
    alt: "linked in",
    img: LAYOUT_IMAGE_URLS.sns.linkedIn,
    url: "https://www.linkedin.com/company/cochl/",
  },
  {
    alt: "github",
    img: LAYOUT_IMAGE_URLS.sns.github,
    url: "https://github.com/cochlearai/",
  },
];

export const NavList = [
  {
    url: "/sound-event-detection",
    component: SoundEventDetection,
  },
  {
    url: "/music-content-analysis",
    component: MusicAnalysis,
  },
  {
    url: "/music-identification",
    component: MusicIdentification,
  },
  {
    url: "/similar-music-recommendation",
    component: MusicRecommendation,
  },
  {
    url: "/speaker-identification",
    component: SpeakerIdentification,
  },
];
