import { SongTitle } from '../constant/GlobalStyles'

const VoiceName = (props:Props) => {

  const {resultsData} = props

  return(
    <>
      <SongTitle>
        <h3>{resultsData.data.result.speaker}</h3>
      </SongTitle>
    </>
  )

}

type Props = {
  resultsData:any
}


export default VoiceName;