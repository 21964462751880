import styled from 'styled-components'
import { Typography } from '../constant/GlobalTypography'
import { ColorTheme } from '../constant/GlobalStyles'
import { ICONS_URLS } from '../constant/Data/ImageUrl'

const BoxArea = styled.div`
  box-sizing: border-box;
  padding: 27px;
  border: 1px solid ${ColorTheme.GrayLight5};
  border-radius: 10px;
  text-align: center;
  margin-bottom: 80px;
`

const CheckIcon = styled.img`
  position: relative;
  top: 2px;
`
const Step = styled.li<{index:number}>`
  display: inline-block;
  box-sizing: border-box;
  padding: 0 20px;
  position: relative;
  text-align: center;
  
`
const Mark = styled.p`
  position: relative;
  display: inline-block;
  ${Typography.body3}
  width: 30px;
  line-height: 25px;
  height: 30px;
  border: 2px solid ${ColorTheme.BlackText};
  border-color: ${ColorTheme.GrayLight5};
  border-radius: 50%;
  background-color: ${ColorTheme.WhiteReal};
  z-index: 10;
  color: ${ColorTheme.GrayLight3};
  > span:last-child {
    display: none;
  }
`
const Description = styled.p`
  ${Typography.body2}
  padding-top: 10px;
  color: ${ColorTheme.GrayLight9};
`
const Line = styled.hr<{front:boolean}>`
  width: 50%;
  position: absolute;
  top: calc(50% - 25px);
  z-index: 5;
  border: 1px solid transparent;
  background-color: ${ColorTheme.GrayLight5};
  left: ${props => props.front? '0' : '50%'};
`

const StepGuide = (props:Props) => {
  const { currentOrder, pageInfo } = props

  const StepContents = styled.ul<{currentOrder:any}>`
    display: inline-flex;
    justify-content: center;
    width: 1020px;
    ${(props) => props.currentOrder >= pageInfo.Step.StepPoint[0]?
      '.back0 {background-color: black}.front1{background-color: black}.mark-bgcolor0{background-color: black; border: 2px solid black;}.mark-bgcolor1{border: 2px solid black}.mark-active0{display:block!important;color:white;}.mark-inactive0{display:none!important}.mark-inactive1{color:black}.description1{color: black}' :
      ''
    }
    ${(props) => props.currentOrder >= pageInfo.Step.StepPoint[1]?
      '.back1 {background-color: black}.front2{background-color: black}.mark-bgcolor1{background-color: black; border: 2px solid black;}.mark-bgcolor2{border: 2px solid black}.mark-active1{display:block!important;color:white;}.mark-inactive1{display:none!important}.mark-inactive2{color:black}.description2{color: black}' :
      ''
    }

  // defualt setting
  .mark-bgcolor0{border: 2px solid black}
  .mark-inactive0{color: black}
  .description0{color: black}
`
  
  return(
    <BoxArea>
      <StepContents currentOrder={currentOrder}>
      {
        pageInfo.Step.StepGuide.map((item:any, index:number) => (
          <Step index={index} key={index}>
            {
              index === 0 ?
              <></>:<Line front={true} className={'front'+index}/>
            }
            <Mark className={'mark-bgcolor'+index}>
              <span className={'mark-inactive'+index}>{index+1}</span>
              <span className={'mark-active'+index}>
                <CheckIcon src={ICONS_URLS.check_white}/>
              </span>
            </Mark>
            <Description className={'description'+index}>{item}</Description>
            {
              index === (pageInfo.Step.StepGuide.length - 1) ?
              <></>:<Line front={false} className={'back'+index}/>
            }
          </Step>
        ))
      }
      </StepContents>
    </BoxArea>
  )

}

type Props = {
  currentOrder: number
  pageInfo: any
}

export default StepGuide;