import {Category, ProcessStep, ResultsCategory, SpeakerId} from '.'

export const SpeakerIdentification = {
    Contents: {
        title: 'Speaker identification',
        shortDescription: 'We can distinguish who he/she is if it is a person in our database.',
        Description: 'There is an AI that can recognize the voices of celebrities. Our speaker identification system can distinguish who he/she is if it is a person in our database. For this demo, we have prepared 10 celebrities that you may know. Just upload 5-10 seconds of audio with the voice of one celebrity on the list and you will get the answer.',
        category: Category.cochlSpeech,
        isWebDemo: true,
    },
    Step: {
        ProcessingStep: [
            ProcessStep.Uploading, 
            ProcessStep.Results
        ],
        StepGuide: ['Upload a file you want to test.','File submitted, wait for the result.'],
        StepPoint: [1],
    },
    Precaution : ['Supported Candidates: Barack Obama, Cardi B, Donald Trump, Emma Watson, Hillary Clinton,<br>Mark Zuckerberg, Oprah Winfrey, Tom Cruise, Will Smith, Yuna Kim', 'Only <b>sound extention files(wav, m4a, mp3, mp4, flac)</b> are available for uploading.','Please upload files <b>under 3MB</b>.'],
    FunctionName: SpeakerId,
    ResultsCategory: ResultsCategory.VoiceName
}

export default SpeakerIdentification