import styled from "styled-components";
import { MAX_WIDTH } from "../constant/GlobalStyles";
import { SoundEventDetection } from "../constant/Data";
import { CheckBox } from "../components";

const CheckBoxListWrap = styled.div`
  position: relative;
  margin-top: -12.5px;
`;

const CheckBoxList = styled.ul`
  margin: 0 auto;
  max-width: calc(${MAX_WIDTH.ProcessMaxWidth} + 145px);
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 1379px) {
    max-width: calc(${MAX_WIDTH.ProcessMaxWidth} - 180px);
  }


  > li {
    width: 300px;
    margin: 12.5px;
  }

  li:first-child,
  li:nth-child(2),
  li:nth-child(3),
  li:nth-child(4) {
    > dl {
      min-height: 346px;
    }
  }

  li:nth-child(5),
  li:nth-child(6),
  li:nth-child(7) {
    > dl {
      min-height: 270px;
    }
  }
`;

const SelectClasses = (props: Props) => {
  const { setServiceList, ServiceList } = props;

  return (
    <>
      <CheckBoxListWrap>
        <CheckBoxList>
          {SoundEventDetection.Classes.map((item: any, index: number) => (
            <li key={index}>
              <CheckBox
                Data={item}
                setServiceList={setServiceList}
                ServiceList={ServiceList}
              />
            </li>
          ))}
          </CheckBoxList>
      </CheckBoxListWrap>
    </>
  );
};

type Props = {
  setServiceList: any;
  ServiceList: any;
};

export default SelectClasses;
