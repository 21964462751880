import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Typography } from "../constant/GlobalTypography";
import { CheckBoxItem } from "../components";
import { ColorTheme } from "../constant/GlobalStyles";

const BoxArea = styled.dl<{ isAvailable: boolean; BorderColor: boolean }>`
  position: relative;
  box-sizing: border-box;
  padding: 24px;
  border: 1px solid
    ${(props) => (props.BorderColor ? ColorTheme.Pink : ColorTheme.GrayLight3)};
  border-radius: 10px;
  opacity: ${(props) => (props.isAvailable ? "1" : "0.3")};
  transition: all 0.25s;

  > label {
    margin-bottom: 20px !important;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: ${(props) => (props.isAvailable ? "none" : "block")};
    z-index: 1;
  }
`;

const Label = styled.label`
  ${Typography.body1 as any}
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover input ~ span {
    background-color: #ccc;
  }
`;

const Category = styled.dt`
  ${Typography.body1 as any}
`;

const ListofSoundsWrap = styled.dd`
  ${Typography.body2 as any}
  display: flex;
  > ul {
    border-left: 2px solid ${ColorTheme.GrayLight8};
    margin-left: 12px;
  }
`;
const ListofSounds = styled.ul``;

const Sound = styled.li`
  &:last-child {
    label {
      margin-bottom: 0;
    }
  }
`;

const InputCheck = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &[name="true"] ~ span:after {
    display: block;
  }
`;
const CheckMark = styled.span<{ isFull: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 26px;
  background-color: ${ColorTheme.GrayLight7};
  border-radius: 2px;
  transition: all 0.25s;

  &:after {
    content: "";
    position: absolute;
    display: none;
    top: ${(props) => (props.isFull ? "6" : "12")}px;
    left: 6px;
    width: 12px;
    height: ${(props) => (props.isFull ? "12" : "2")}px;
    border-radius: 2px;
    background-color: ${ColorTheme.Pink};
  }
`;

const CheckBox = (props: Props) => {
  const { Data, setServiceList, ServiceList } = props;
  const SoundsGroup = useRef<any>(null);
  const CategoryBox = useRef<any>(null);
  const [IsUpdateAll, setIsUpdateAll] = useState(true);
  const [PinkBorder, setPinkBorder] = useState(false);
  const [IsFull, setIsFull] = useState(false);
  let soundList = [...ServiceList];
  let updateNumber: number = 0;

  useEffect(() => {
    let ChildNode = [...SoundsGroup.current.childNodes];

    ChildNode.map((Item: any) => {
      const checkedNode = Item.children[0].children[0];
      const checkedValue = checkedNode.attributes["value"].value;
      const IsHere: any = soundList.indexOf(checkedValue);
      if (IsHere !== -1) {
        checkedNode.setAttribute("name", "true");
        updateNumber = updateNumber + 1;
      } else {
        checkedNode.setAttribute("name", "false");
      }
    });

    if (updateNumber === 0) {
      setPinkBorder(false);
      CategoryBox.current.setAttribute("name", "false");
    } else if (updateNumber === Data.sounds.length) {
      setPinkBorder(true);
      setIsFull(true);
      CategoryBox.current.setAttribute("name", "true");
    } else {
      setPinkBorder(true);
      setIsFull(false);
      CategoryBox.current.setAttribute("name", "true");
    }
    // console.log(updateNumber)
  }, [ServiceList]);

  const UpdateAll = async (toggle: boolean) => {
    let pushArray: any = [];
    let ChildNode = [...SoundsGroup.current.childNodes];

    await ChildNode.map(async (Item: any) => {
      const checkedNode = Item.children[0].children[0];
      const checkedValue = checkedNode.attributes["value"].value;
      const IsHere: any = soundList.indexOf(checkedValue);
      const Check = (item: any) => {
        return item !== checkedValue;
      };
      // Select All
      if (toggle) {
        if (IsHere === -1) {
          pushArray.push(checkedValue);
        }
      }
      // Remove All
      else {
        soundList = soundList.filter(Check);
      }
    });
    // Select All
    if (toggle) {
      const updateArray = [...ServiceList, ...pushArray];
      setServiceList(updateArray);
    }
    // Remove All
    else {
      setServiceList(soundList);
    }
  };

  const categoryChange = async (e: any) => {
    setIsUpdateAll(!IsUpdateAll);
    UpdateAll(IsUpdateAll);
  };

  return (
    <>
      <BoxArea isAvailable={Data.isAble} BorderColor={PinkBorder}>
        <Label>
          <InputCheck
            type="checkbox"
            name={Data.category}
            value={Data.category}
            onChange={categoryChange}
            ref={CategoryBox}
          />
          <CheckMark isFull={IsFull} />
          <Category>{Data.category}</Category>
        </Label>
        <ListofSoundsWrap>
          <ListofSounds ref={SoundsGroup}>
            {Data.sounds.map((item: any, index: number) => (
              <Sound key={index}>
                <CheckBoxItem
                  label={item[0]}
                  value={item[1]}
                  setServiceList={setServiceList}
                  ServiceList={ServiceList}
                />
              </Sound>
            ))}
          </ListofSounds>
        </ListofSoundsWrap>
      </BoxArea>
    </>
  );
};

type Props = {
  Data: any;
  setServiceList: any;
  ServiceList: any;
};

export default CheckBox;
