import {Category, ProcessStep, ResultsCategory, MusicId} from '.'

export const MusicIdentification = {
    Contents: {
        title: 'Music identification',
        shortDescription: 'Find out what the name of the song is that you wonder with a free license music database.',
        Description: 'Sometimes when you go shopping, you like the music in the store so much that you wonder what the name of the song is. In that case, our music identification technology can help you out. Try our demo to check how it works with a free license music database. In this demo, you will need a laptop with a microphone for recording music and a smartphone with a speaker for playing music.',
        category: Category.cochlMusic,
        isWebDemo: true,
    },
    Step: {
        ProcessingStep: [
            ProcessStep.QRCode, 
            ProcessStep.Recording, 
            ProcessStep.Results
        ],
        StepGuide: ['Tag the QR code to access to the song list.','Play a music, and record it in the computer.','File submitted, wait for the result.'],
        StepPoint: [1, 2],
    },
    Precaution : ['Tag <b>the QR code</b> from your phone to record it on the computer.','Play the song for at least <b>10 seconds</b>.'],
    FunctionName: MusicId,
    ResultsCategory: ResultsCategory.SongNameID
}

export default MusicIdentification