const { REACT_APP_IMAGE_URL: imageURL } = process.env;

export const ROOT_PATH = imageURL;

export const IMAGE_DIRECTORY_PATH = {
  header: `${ROOT_PATH}/header`,
  footer: `${ROOT_PATH}/footer`,
  icons: `${ROOT_PATH}/icons`,
  general: `${ROOT_PATH}/general`,
  error: `${ROOT_PATH}/404`,
  axis: `${ROOT_PATH}/axis`,
  labs: `${ROOT_PATH}/labs`,
};

export const LAYOUT_IMAGE_URLS = {
  logo_header: `${IMAGE_DIRECTORY_PATH.header}/header_logo_dark.png`,
  logo_footer: `${IMAGE_DIRECTORY_PATH.footer}/Logo_cochl_footer.png`,
  sns: {
    linkedIn: `${IMAGE_DIRECTORY_PATH.footer}/footer_icon_linkedIn.svg`,
    github: `${IMAGE_DIRECTORY_PATH.footer}/footer_icon_github.svg`,
    medium: `${IMAGE_DIRECTORY_PATH.footer}/footer_icon_medium.svg`,
  },
};

export const MAIN_IMAGE_URLS = {
  banner: `${IMAGE_DIRECTORY_PATH.labs}/banner_revised.png`,
  commingSoon: `${IMAGE_DIRECTORY_PATH.labs}/Comming_Soon_opacity.png`,
};

export const ICONS_URLS = {
  uploading: `${IMAGE_DIRECTORY_PATH.labs}/uploading_icon.svg`,
  recording: `${IMAGE_DIRECTORY_PATH.labs}/Recording_icon.svg`,
  stop: `${IMAGE_DIRECTORY_PATH.labs}/Stop_icon.svg`,
  QR: `${IMAGE_DIRECTORY_PATH.labs}/QR_MusicList.png`,
  check_white: `${IMAGE_DIRECTORY_PATH.labs}/check_white_icon.svg`,
  check_black: `${IMAGE_DIRECTORY_PATH.labs}/check_black_icon.svg`,
  check_bg: `${IMAGE_DIRECTORY_PATH.labs}/check_icon_bg.svg`,
  X_icon: `${IMAGE_DIRECTORY_PATH.labs}/X_icon.svg`,
};
