import { ICONS_URLS } from '../constant/Data'
import { ColorTheme } from '../constant/GlobalStyles'
import styled from 'styled-components'
import { Typography } from '../constant/GlobalTypography'

const CheckArea = styled.div`
  text-align: center;
  padding-bottom: 60px;
`
const IconArea = styled.span`
  display: inline-block;
  width: 26px;
  line-height: 26px;
  border-radius: 50%;
  background-color: ${ColorTheme.PurpleLight};
`
const CheckIcons = styled.img`
  display: inline-block;
`
const PageName = styled.p`
  display: block;
  padding-top: 10px;
  ${Typography.caption}
  color: ${ColorTheme.GrayText};
`

const ResultsCheck = (props:Props) => {
  const {pageInfo,resultsData} = props
  const Fail = resultsData.status !== 200 || resultsData.data.status !=="success"

  return(
    <CheckArea>
      <IconArea>
        { Fail?
          <CheckIcons src={ICONS_URLS.X_icon}/>
          :<CheckIcons src={ICONS_URLS.check_black}/>
        }
      </IconArea>
      <PageName>
        { Fail?
          'Failed to analyze'
          :pageInfo.Contents.title
        }
      </PageName>
    </CheckArea>
  )

}


type Props = {
  pageInfo: any
  resultsData: any
}

export default ResultsCheck