import styled from 'styled-components'
import { Typography } from '../constant/GlobalTypography'

// ask seniors to fix this

const SongTimeSliderWrap = styled.div`
`

const SongTimeIndicator = styled.div`
  position: relative;
  margin: 5px 16px 16px 16px;
  display: flex;
  color: #B9BABE;
  ${Typography.body2}
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
`

const SongCurrentTime = styled.div`
  flex-grow: 1;
`

const SongRemainTime = styled.div`
`

const SliderBar = styled.input`
  position: relative;
  left: -2px;
  margin-top: 25px;
  -webkit-appearance: none;
  appearance: none;
  width: 100%; 
  height: 5px; 
  outline: none; 
  opacity: 0.7;  
  background-color: #F2F2F2;
  
  &:hover {
    cursor: pointer;
  }

  &::-webkit-slider-thumb {
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #131313;
    cursor: pointer;
    z-index: 1;
  }

  &::-moz-range-thumb {
    width: 10px;
    height: 10px; 
    border-radius: 50%;
    background: #131313;
    cursor: pointer;
  }
`

const EmptySongTimeSlider = () => {
  return (
    <SongTimeSliderWrap>
      <SliderBar
        type='range'
        value={0}
        max={0}
      />
      <SongTimeIndicator>
        <SongCurrentTime>00:00</SongCurrentTime>
        <SongRemainTime>-00:00</SongRemainTime>
      </SongTimeIndicator>
    </SongTimeSliderWrap>
  )
}

export default EmptySongTimeSlider