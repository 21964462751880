// © wavesurfer : https://wavesurfer-js.org/
import styled from 'styled-components'
import React, { useEffect, useRef } from "react";
import WaveSurfer from "wavesurfer.js";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { ColorTheme } from '../constant/GlobalStyles';

const SoundGraphAreaWrap = styled.div`
  display: flex;
  justify-content: center;
`
const SoundGraphArea = styled.div`
  position: relative;
  left: 20px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
`
const SoundArea = styled.div`
  box-sizing: border-box;
  padding-left: 217px;
  position: relative;
  top: 16px;
`
const SoundWave = styled.div`
  width: 620px;
`
const BackgroundArea = styled.div<{Graph:string}>`
  height: 380px;
  background-image: url(${props => props.Graph});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 130px 0;
`
const PlayButtonArea = styled.div`
  margin-top: 40px;
  margin-right: 65px;
  text-align: center;
`
const PlayButton = styled.button<{isPlaying: boolean}>`
  height: 46px;
  width: 46px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${ColorTheme.GrayLight6};
  border-color: ${props => props.isPlaying ? ColorTheme.BlackText : ColorTheme.GrayLight6};
  color: ${props => props.isPlaying ? ColorTheme.BlackText : ColorTheme.GrayLight6};
  background-color: ${ColorTheme.WhiteReal};
  &:hover {
    border-color: ${ColorTheme.BlackText};
    color: ${ColorTheme.BlackText};
  }
`


const BackgroundImage = (props:any) => {
  const { children, Graph } = props
  return(
    <>
    <BackgroundArea Graph={Graph}>
      {children}
    </BackgroundArea>
    </>
  )
}

const SoundGraph = (props:Props) => {
  const {
    resultsData,
    SoundWaveModule,
    setSoundWaveModule,
    playing,
    setPlay,
  } = props
  const waveformRef = useRef<any>(null);

  useEffect(() => {

    const wavesurfer = WaveSurfer.create({
        container: waveformRef.current!,
        scrollParent: true,
        waveColor: 'rgba(0, 0, 0, 0)',
        progressColor: 'rgba(0, 0, 0, 0.2)',
        height: 307,
        fillParent: true,
    })
    wavesurfer.load(resultsData.data.result.audio_path);
    setSoundWaveModule(wavesurfer)
    wavesurfer.zoom(1)
    wavesurfer.on('finish', function() {
      setPlay(false);
  });
  },[])

  const handlePlayPause = () => {
    setPlay(!playing);
    SoundWaveModule.playPause();
  };

  return (
    <>
      <SoundGraphAreaWrap>
      <SoundGraphArea>
      <BackgroundImage Graph={resultsData.data.result.img_path}>
        <SoundArea>
          <SoundWave ref={waveformRef}></SoundWave>
        </SoundArea>
      </BackgroundImage>
      <PlayButtonArea>
        <PlayButton onClick={handlePlayPause} isPlaying={playing}>
          {!playing ? <PlayArrowIcon/> : <PauseIcon/>}
        </PlayButton>
          </PlayButtonArea>
        </SoundGraphArea>
        </SoundGraphAreaWrap>
    </>
  )
}

type Props = {
  resultsData:any
  SoundWaveModule: any
  setSoundWaveModule: any
  playing: any
  setPlay: any
}

export default SoundGraph;
