import { ResultsCategory } from '../constant/Data'
import { 
  SoundGraph, 
  SoundAnalysis, 
  SongNameID, 
  SongNameRecommend, 
  VoiceName } from '.'
import styled from 'styled-components'
import { ColorTheme } from '../constant/GlobalStyles'
import { Typography } from '../constant/GlobalTypography'

const FailBoxArea = styled.div`
  text-align: center;
`
const FailBox = styled.ul`
  margin: 0;
  display: inline-block;
  width: 650px;
  border: 7px solid ${ColorTheme.PurpleLight};
  padding: 20px 0;
  li {
    ${Typography.body2}
    color: ${ColorTheme.GrayText};
    padding: 0;
    margin: 0;
    b {
      font-weight: 600;
    }
  }
`
const Capital = styled.span`
  text-transform: uppercase;
`
const FirstLetterCapital = (props:any) => {
  const {context} = props
  const firstLetter = context.substring(0,1)
  const remainLetter = context.substring(1,context.lengtth)

  return <><b><Capital>{firstLetter}</Capital>{remainLetter}</b></>
}

const Results = (props:Props) => {
  const {
    pageInfo, 
    resultsData,
    SoundWaveModule,
    setSoundWaveModule,
    playing,
    setPlay, } = props

  const resultsCategory = pageInfo.ResultsCategory
  const ServerFail = resultsData.status !== 200
  const ResultFail = resultsData.data.status !=="success"

  if(ServerFail) {
    return(
      <FailBoxArea>
        <FailBox>
          <li>Network is not stable currently.</li>
          <li>Please try again.</li>
        </FailBox>
      </FailBoxArea>
    )
  } else if(ResultFail) {
    return(
      <FailBoxArea>
        <FailBox>
          <li>Sounds couldn’t be analyzed.</li>
          <li>Reason: <FirstLetterCapital context={resultsData.data.result.error}/></li>
        </FailBox>
      </FailBoxArea>
    )
  } else {
    switch(resultsCategory) {
      case ResultsCategory.SoundGraph: 
        return <SoundGraph 
                  resultsData={resultsData}
                  SoundWaveModule={SoundWaveModule}
                  setSoundWaveModule={setSoundWaveModule}
                  playing={playing}
                  setPlay={setPlay}/>
      case ResultsCategory.SoundAnalysis: 
        return <SoundAnalysis resultsData={resultsData}/>
      case ResultsCategory.SongNameID: 
        return <SongNameID
                  resultsData={resultsData}/>
      case ResultsCategory.SongNameRecommend: 
        return <SongNameRecommend
                resultsData={resultsData}/>
      case ResultsCategory.VoiceName: 
        return <VoiceName 
                  resultsData={resultsData}/>
    }
  }
  return<></>
}

type Props = {
  pageInfo: any
  resultsData: any
  SoundWaveModule: any
  setSoundWaveModule: any
  playing: any
  setPlay: any
}

export default Results