import styled from 'styled-components'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';

const ControlButtonStyle = styled.div<{ isPlaying: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.isPlaying ? '100px' : '70px'};
  height: ${props => props.isPlaying ? '100px' : '70px'};
  border: 1px solid;
  border-radius: 50%;
  border-color: ${props => props.isPlaying ? '#DB2E8A' : '#A3A3B2'};
  background-color: ${props => props.isPlaying ? '#FFF0F8' : 'transparent'};

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  // animation: pulse 1.5s infinite;
  animation-name: ${props => props.isPlaying ? 'pulse' : 'nothing'};
  animation-duration: ${props => props.isPlaying ? '2s' : 'nothing'};
  animation-iteration-count: ${props => props.isPlaying ? 'infinite' : 'nothing'};
  @keyframes pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(219, 46, 138, 0.7);
    }
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 30px rgba(219, 46, 138, 0);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(219, 46, 138, 0);
    }
  }
`

const ControlButton = (props: Props) => {
  
  const {setPlay, setPause, isPlaying} = props

  const play = () => {
    setPlay();
  }

  const pause = () => {
    setPause();
  } 

  const handleOnClick = () => {
    if(isPlaying) {
      pause()
    } else {
      play()
    }
  }

  return (
    <>
      <ControlButtonStyle id='control_button' isPlaying={isPlaying} onClick={handleOnClick}>
          {isPlaying ? <StopIcon id="stop_icon"/> : <PlayArrowIcon id='play_arrow_icon'/>}
      </ControlButtonStyle>
    </>
  )
}

type Props = {
  setPlay: any,
  setPause:any,  
  isPlaying: boolean
}

export default ControlButton