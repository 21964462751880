import styled from 'styled-components'
import { SNS, Footer_Data, LAYOUT_IMAGE_URLS } from '../constant/Data'
import { Typography } from '../constant/GlobalTypography'
import { DefaultInnerwrap, ColorTheme, BreakPoint } from '../constant/GlobalStyles'
import { UnstyledLink } from '../components/UnstyledLink'

const FooterArea = styled.div`
    background-color: ${ColorTheme.BlackReal};
`
const FooterInnerwrap = styled(DefaultInnerwrap)`
    padding: 65px 40px;
    background-color: ${ColorTheme.BlackReal};
`
const LogoArea = styled.p`
    > img {
        width: 150px;
    }
`
const ContentsArea = styled.ul`
    padding-top: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${ColorTheme.WhiteReal};
    > li:first-child {
        display: flex;
        align-items: center;
    }
    @media screen and (max-width: ${BreakPoint.small}) {
        display: block;
        > li:last-child {
            padding-top: 20px;
        }
    }
`
const SnsArea = styled.ul`
    display: flex;
    align-items: center;
    li {
        padding-right: 15px;
        &:last-child {
            padding-right: 0;
        }
    }
    li:first-child img {
        width: 27px !important;
    }
    img {
        width: 20px;
    }
`
const Copyright = styled.p`
    ${Typography.body2}
`
const Policy = styled.ul`
    display: flex;
    padding-left: 30px;

    > li {
        display: inline-block;
        &:first-child {
            padding-right: 10px;
            border-right: 1px solid ${ColorTheme.WhiteReal};
        }
        &:last-child {
            padding-left: 10px;
        }
    }
    a {
        ${Typography.body2};
        line-height: 1;
    }
`

const Footer = () => {
    return(
        <FooterArea>
            <FooterInnerwrap>
                <LogoArea>
                    <img src={LAYOUT_IMAGE_URLS.logo_footer} alt=""/>
                </LogoArea>
                <ContentsArea>
                    <li>
                        <Copyright>{Footer_Data.copyright}</Copyright>
                        <Policy>
                            {
                                Footer_Data.policy.map((item:any, index:number) => (
                                    <li key={index}>
                                        <UnstyledLink url={item.url}>
                                            {item.title}
                                        </UnstyledLink>
                                    </li>
                                ))
                            }
                        </Policy>
                    </li>
                    <li>
                        <SnsArea>
                            {
                                SNS.map((item:any, index:number) => (
                                    <li key={index}>
                                        <UnstyledLink url={item.url}>
                                            <img src={item.img} alt={item.alt}/>
                                        </UnstyledLink>
                                    </li>
                                ))
                            }
                        </SnsArea>
                    </li>
                </ContentsArea>
            </FooterInnerwrap>
        </FooterArea>
    )
}

export default Footer