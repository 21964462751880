import { styled, Box } from '@mui/system';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import { Typography } from '../constant/GlobalTypography'
import { ColorTheme } from '../constant/GlobalStyles'

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;
const style = {
  width: 540,
  bgcolor: ColorTheme.GrayLight2,
  borderRadius: '4px',
};
const ModalInner = styled('div')`
  padding: 50px;
`
const Description = styled('p')`
  ${Typography.h3}
  padding-bottom: 15px;
`
const SubDescription = styled('p')`
  padding-top: 5px;
  ${Typography.h6}
  color: ${ColorTheme.GrayLight10};
  >b {
    color: ${ColorTheme.BlackText};
  }
`
const CheckArea = styled('div')`
  text-align: right;
`
const Confirm = styled('span')`
  padding-top: 55px;
  display: inline-block;
  ${Typography.button}
  color: ${ColorTheme.Blue2};
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
`

export const ModalPopup = (props:Props) => {
  const {openPopup, setModalOpen, ErrorCode} = props
  const handleClose = () => setModalOpen(false);

  switch(ErrorCode) {
    case 0:
      return(
        <>
          <div>
          <StyledModal
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            open={openPopup}
            onClose={handleClose}
            BackdropComponent={Backdrop}
          >
            <Box sx={style}>
              <ModalInner>
                <Description>Please check the file size.</Description>
                <SubDescription>You can only upload files smaller than <b>3 MB</b>.</SubDescription>
                <CheckArea>
                  <Confirm onClick={handleClose}>CONFIRM</Confirm>
                </CheckArea>
              </ModalInner>
            </Box>
          </StyledModal>
        </div>
        </>
      )
    case 1:
      return(
        <>
          <div>
          <StyledModal
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            open={openPopup}
            onClose={handleClose}
            BackdropComponent={Backdrop}
          >
            <Box sx={style}>
              <ModalInner>
                <Description>Please check the file extension.</Description>
                <SubDescription>Only sound extention files<b>(wav, m4a, mp3, mp4, flac)</b> are available for uploading.</SubDescription>
                <CheckArea>
                  <Confirm onClick={handleClose}>CONFIRM</Confirm>
                </CheckArea>
              </ModalInner>
            </Box>
          </StyledModal>
        </div>
        </>
      )
    case 2:
      return(
        <>
          <div>
          <StyledModal
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            open={openPopup}
            onClose={handleClose}
            BackdropComponent={Backdrop}
          >
            <Box sx={style}>
              <ModalInner>
                <Description>Unable to access microphone.</Description>
                <SubDescription>Please allow Cochl.Labs to access your microphone from device/browser settings.</SubDescription>
                <CheckArea>
                  <Confirm onClick={handleClose}>CONFIRM</Confirm>
                </CheckArea>
              </ModalInner>
            </Box>
          </StyledModal>
        </div>
        </>
      )
  }

  return <></>
  
}

type Props = {
  openPopup: boolean
  setModalOpen: any
  ErrorCode: number
}
export default ModalPopup