import styled from 'styled-components'
import { ColorTheme, SongTitle } from '../constant/GlobalStyles'
import { Typography } from '../constant/GlobalTypography'
import LaunchIcon from '@mui/icons-material/Launch';

const LinkArea = styled.div`
  margin-top: 40px;
  text-align: center;
`
const LinkButton = styled.a`
  display: inline-block;
  border: 1px solid ${ColorTheme.GrayLight3};
  border-radius: 100px;
  ${Typography.button}
  padding: 10px 40px;
  color: ${ColorTheme.GrayLight3};
  > span {
    padding-right: 15px;
  }
  span, svg {
    vertical-align: middle;
  }
`

const SongNameRecommend = (props:Props) => {

  const {resultsData} = props

  return(
    <>
      <SongTitle>
        <h3>{resultsData.data.result.result.song}</h3>
        <span>{resultsData.data.result.result.artist}</span>
      </SongTitle>
      <LinkArea>
        <LinkButton href={resultsData.data.result.result.url} target="_blank"><span>DOWNLOAD THIS FILE</span><LaunchIcon/></LinkButton>
      </LinkArea>
      
    </>
  )

}

type Props = {
  resultsData:any
}


export default SongNameRecommend;