import {Category, ProcessStep, ResultsCategory, MusicRecommend} from '.'

export const MusicRecommendation = {
    Contents: {
        title: 'Similar music recommendation',
        shortDescription: 'We recommend you songs that are similar to your favorite songs.',
        Description: "One of the ways to find new songs to suit your taste is by recommending songs that are similar to your favorite songs. Our music recommendation lets you find a similar song you upload by analyzing only the audio signal. Since it doesn't use meta information like artist, title, etc., you can get purely similar music. This system is especially useful for solving copyright issues by replacing licensed music with similar unlicensed music. We’ve prepared songs that don't have copyright issues, so give it a try.",
        category: Category.cochlMusic,
        isWebDemo: true,
    },
    Step: {
        ProcessingStep: [
            ProcessStep.Uploading, 
            ProcessStep.Results
        ],
        StepGuide: ['Upload a music file you want to analyze.', 'File submitted, wait for the result.'],
        StepPoint: [1],
    },
    Precaution : ['Only <b>sound extention files(wav, m4a, mp3, mp4, flac)</b> are available for uploading.','Please upload files <b>under 3MB</b>.'],
    FunctionName: MusicRecommend,
    ResultsCategory: ResultsCategory.SongNameRecommend
}

export default MusicRecommendation