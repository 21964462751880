import {
  IMAGE_DIRECTORY_PATH 
} from '.'

export const SONG_DIRECTORY_PATH = `${IMAGE_DIRECTORY_PATH.labs}/artlist_for_fp`

export const SONG_URL = [
  {
    songName: 'Taurus',
    songUrl: `${SONG_DIRECTORY_PATH}/429546__428854_Asaf_Azoulay_-_Rockorona_-_Track_1_-_AO-000107-_1_-_Master_V2_-_111120_-_BOV_-_ORG_-_2444.mp3`,
    songAuthor: 'Ace'
  },
  {
    songName: 'Looking For',
    songUrl: `${SONG_DIRECTORY_PATH}/409730__JSquared_-_Looking_For_-_051020_-_EXT_-_X_-_2444_.mp3`,
    songAuthor: 'JSquared'
  },
  {
    songName: 'Pray',
    songUrl: `${SONG_DIRECTORY_PATH}/442534__441662_Marcus_Roynaldo_Williams_-_Cinematic_Spell_-_AO-000128_-_2_-_Master_V1b-241120-BOV-ORG-2444.mp3`,
    songAuthor: 'Duce Williams'
  },
  {
    songName: 'Caution',
    songUrl: `${SONG_DIRECTORY_PATH}/caution by skrxlla Artlist.mp3`,
    songAuthor: 'Skrxlla'
  },
  {
    songName: 'OMW',
    songUrl: `${SONG_DIRECTORY_PATH}/424658__424018_Gil_Lewis_-_Sounds_Of_Now_-_OMW_-__AO-000108_-_2_-_Master_V3_-_261020_-_BOV_-_ORG_-_2444_.mp3`,
    songAuthor: 'Jane & The Boy'
  },
  {
    songName: 'Half as Good',
    songUrl: `${SONG_DIRECTORY_PATH}/451584__Benj_Heard_-_Half_As_Good_-_030121_-_EXT_-_X_-_2444.mp3`,
    songAuthor: 'Benj Heard'
  },
  {
    songName: 'My Cup Is Nearly Empty',
    songUrl: `${SONG_DIRECTORY_PATH}/448456__David_Jack_Marshall_-_My_Cup_Is_Nearly_Empty_-_271220_-_EXT_-_X_-_2444.mp3`,
    songAuthor: 'David Jack Marshall'
  },
  {
    songName: 'Tokyo',
    songUrl: `${SONG_DIRECTORY_PATH}/385587__Nbdy_Nprtnt_-_Tokyo_-_280620_-_EXT_-_X_-_2444___.mp3`,
    songAuthor: 'Nbdy Nprtnt'
  },
  {
    songName: 'Mid Side',
    songUrl: `${SONG_DIRECTORY_PATH}/452584__Mintz_-_Mid_Side_-_040121_-_EXT_-_X_-_2444_.mp3`,
    songAuthor: 'Leon Nikishayev'
  },
  {
    songName: 'We\'re Going Home',
    songUrl: `${SONG_DIRECTORY_PATH}/442533__441664_Marcus_Roynaldo_Williams_-_Cinematic_Spell_-_AO-000128_-_1_-_Master_V1b-231120-BOV-ORG-2444.mp3`,
    songAuthor: 'Duce Williams'
  },
  {
    songName: 'Kumare Brothers',
    songUrl: `${SONG_DIRECTORY_PATH}/426001__Dialgo_-_Kumare_-_221020_-_EXT_-_X_-_2444.mp3`,
    songAuthor: 'Dialgo'
  },
  {
    songName: 'Yes Man',
    songUrl: `${SONG_DIRECTORY_PATH}/39965_Yes_Man_-_Master_(16-44.1).mp3`,
    songAuthor: 'Nate Rose'
  },
  {
    songName: 'I Get Up',
    songUrl: `${SONG_DIRECTORY_PATH}/i-get-up by john-coggins Artlist.mp3`,
    songAuthor: 'John Coggins'
  },
  {
    songName: 'Breathe',
    songUrl: `${SONG_DIRECTORY_PATH}/breathe by ian-post Artlist.mp3`,
    songAuthor: 'Ian Post'
  },
  {
    songName: 'Having a Good Day',
    songUrl: `${SONG_DIRECTORY_PATH}/339480__Benj_Heard_-_Having_A_Good_Day_-_030620_-_EXT_-_X_-_2444.mp3`,
    songAuthor: 'Benj Heard'
  },
  {
    songName: 'Yearning',
    songUrl: `${SONG_DIRECTORY_PATH}/446561__Michael_FK_-_Yearning_-_221220_-_EXT_-_X_-_2444__.mp3`,
    songAuthor: 'Michael FK'
  },
  {
    songName: 'Fall into You',
    songUrl: `${SONG_DIRECTORY_PATH}/452863__Far_West_-_Fall_into_You_-_040121_-_EXT_-_X_-_2444.mp3`,
    songAuthor: 'Far West'
  },
  {
    songName: 'Paranormal - Instrumental Version',
    songUrl: `${SONG_DIRECTORY_PATH}/314693__Jay_Ray_-_Paranormal_-_Instrumental_-_260420_-_EXT_-_X_-_2444_.mp3`,
    songAuthor: 'Jay Ray'
  },
  {
    songName: 'FreeWheel',
    songUrl: `${SONG_DIRECTORY_PATH}/freewheel by rex-banner Artlist.mp3`,
    songAuthor: 'Rex Banner'
  },
  {
    songName: 'Needs',
    songUrl: `${SONG_DIRECTORY_PATH}/250726__Needs_-_Master_(24-44.1)RG.mp3`,
    songAuthor: 'Nolai'
  },
  {
    songName: 'Nothing Can Keep Me from You',
    songUrl: `${SONG_DIRECTORY_PATH}/382805__382175_Bunker_Buster_(Omer_Schonberger)_-_Pop_Diva_-_Nothing_Can_Keep_Me_From_You_-_Master_V2_-_110820_-_INT_-_2444.mp3`,
    songAuthor: 'Denitia'
  },
  {
    songName: 'Relaxed Fit',
    songUrl: `${SONG_DIRECTORY_PATH}/349385__SkyGaze_-_Relaxed_Fit_-_140620_-_EXT_-_X_-_2444.mp3`,
    songAuthor: 'Skygaze'
  },
  {
    songName: 'Winter Weather',
    songUrl: `${SONG_DIRECTORY_PATH}/446995__ARose_-_Winter_Weather_-_231220_-_EXT_-_X_-_2444.mp3`,
    songAuthor: 'A\'Rose'
  },
  {
    songName: 'Love You Better',
    songUrl: `${SONG_DIRECTORY_PATH}/432541__Aaron_Kellim_-_Love_You_Better_-_231120_-_EXT_-_X_-_2444__.mp3`,
    songAuthor: 'Aaron Kellim'
  },
  {
    songName: 'Tech Knowledge',
    songUrl: `${SONG_DIRECTORY_PATH}/tech-knowledge by peter-spacey Artlist.mp3`,
    songAuthor: 'Peter Spacey'
  },
  {
    songName: 'Over the Horizon',
    songUrl: `${SONG_DIRECTORY_PATH}/453412__Aleksey_Chistilin_-_Over_the_Horizon_-_050121_-_EXT_-_X_-_2444__.mp3`,
    songAuthor: 'Aleksey Chistilin'
  },
  {
    songName: 'Maybe',
    songUrl: `${SONG_DIRECTORY_PATH}/455517__Far_West_-_Maybe_-_040121_-_EXT_-_X_-_2444.mp3`,
    songAuthor: 'Far West'
  },
  {
    songName: 'Rise and Shout',
    songUrl: `${SONG_DIRECTORY_PATH}/rise-and-shout by soundroll Artlist.mp3`,
    songAuthor: 'Soundroll'
  },
  {
    songName: 'Flutez',
    songUrl: `${SONG_DIRECTORY_PATH}/448868__448507_Yanivi_-_BassMode_-_Track_1_-_AO-000061_-1-_Master_V1.2_-_081120_-_BOV_-_ORG_-_2444.mp3`,
    songAuthor: 'Yanivi'
  },
  {
    songName: 'See the Light',
    songUrl: `${SONG_DIRECTORY_PATH}/409673__Evgeny_Bardyuzha_-_See_the_light_-_051020_-_EXT_-_X_-_2444.mp3`,
    songAuthor: 'Evgeny Bardyuzha'
  },
  {
    songName: 'Stunned Man',
    songUrl: `${SONG_DIRECTORY_PATH}/74865_StunnedMan_-_Master_(16-44.1).mp3`,
    songAuthor: 'Nils Baumgärtel'
  },
  {
    songName: 'Ultra Light',
    songUrl: `${SONG_DIRECTORY_PATH}/ultra-light by the-cliff Artlist.mp3`,
    songAuthor: 'The Cliff'
  },
  {
    songName: 'Will You Dance with Me',
    songUrl: `${SONG_DIRECTORY_PATH}/427070__426297_Roy_Yarom_-_Uplifti-Fi_-_Will_You_Dance_With_Me_-_AO-000091_-_1_-_Master_V1_-_281020_-_BOV_-_ORG_-_2444.mp3`,
    songAuthor: 'Lalinea'
  },
  {
    songName: 'Free',
    songUrl: `${SONG_DIRECTORY_PATH}/439701__Third_Dimension_-_Free_-_071220_-_EXT_-_X_-_2444.mp3`,
    songAuthor: 'Third Dimension'
  },
  {
    songName: 'Forever Girl',
    songUrl: `${SONG_DIRECTORY_PATH}/63585_Forever_Girl_-_Master_(16-44.1).mp3`,
    songAuthor: 'Chaun Davis'
  },
  {
    songName: 'New Land',
    songUrl: `${SONG_DIRECTORY_PATH}/new-land by ian-post Artlist.mp3`,
    songAuthor: 'Ian Post'
  },
  {
    songName: 'Now They Know',
    songUrl: `${SONG_DIRECTORY_PATH}/39618_08_-_Now_They_Know_(16-44.1).mp3`,
    songAuthor: 'Mogli the Iceburg'
  },
  {
    songName: 'Tempted',
    songUrl: `${SONG_DIRECTORY_PATH}/226341__Assaf_Ayalon_-_Tempted_-_Remake_-_Tempted_-_Master_(24-44.1)RG.mp3`,
    songAuthor: 'The Hazelnuts'
  },
  {
    songName: '4REAL',
    songUrl: `${SONG_DIRECTORY_PATH}/435697__Wxlf_-_4REAL_-_291120_-_EXT_-_X_-_2444.mp3`,
    songAuthor: 'Wxlf'
  },
  {
    songName: 'Blind',
    songUrl: `${SONG_DIRECTORY_PATH}/108450_Blind_(16-44.1).mp3`,
    songAuthor: 'Kaput Blue'
  },
  {
    songName: 'Young One',
    songUrl: `${SONG_DIRECTORY_PATH}/80620_Young_One_(16-44.1).mp3`,
    songAuthor: 'Kipp Wilde'
  },
  {
    songName: 'Golden Tooth',
    songUrl: `${SONG_DIRECTORY_PATH}/382900__382505_Eyal_Talmudi_-_Bagpipe_Solo_-_Golden_Tooth_Big_-_Master_V3_PITCH_TUNE_-_INT_-_X_-_2444.mp3`,
    songAuthor: 'Eyal Talmudi'
  },
  {
    songName: 'Wasted Time',
    songUrl: `${SONG_DIRECTORY_PATH}/79451_Wasted_Time_(16-44.1).mp3`,
    songAuthor: 'Nate Rose'
  },
  {
    songName: 'Bluerise',
    songUrl: `${SONG_DIRECTORY_PATH}/bluerise by oliver-michael Artlist.mp3`,
    songAuthor: 'Oliver Michael'
  },
  {
    songName: 'Play with Fire',
    songUrl: `${SONG_DIRECTORY_PATH}/458923__RocknStock_-_Play_with_Fire_-_140121_-_EXT_-_X_-_2444.mp3`,
    songAuthor: 'RocknStock'
  },
  {
    songName: 'All This Love',
    songUrl: `${SONG_DIRECTORY_PATH}/54660_07_-_All_This_Love_(16-44.1).mp3`,
    songAuthor: 'Russo and Weinberg'
  },
  {
    songName: 'Trippin\'',
    songUrl: `${SONG_DIRECTORY_PATH}/130750_Stevie_Ross_(Dor_Reznik)_-_When_Skies_Are_Grey_-_03_-_Trippin_-_Master_(24-44.1)RG.mp3`,
    songAuthor: 'Stevie Ross'
  },
  {
    songName: 'Hiving',
    songUrl: `${SONG_DIRECTORY_PATH}/345866__343256_07_Dan_Mayo_And_Yahezkel_Raz_-_HIVE_-_Hiving_-_300420_-_INT_-_X_-_2444.mp3`,
    songAuthor: 'Dan Mayo'
  },
  {
    songName: 'United',
    songUrl: `${SONG_DIRECTORY_PATH}/433189__430489_Itay_Kashti_-_Standing_On_The_Edge_-_AO-000104_-1_-_Master_V2_-_111120_-_BOV_-_ORG_-_2444.mp3`,
    songAuthor: 'Itay Kashti'
  },
  {
    songName: 'We Won\'t Survive',
    songUrl: `${SONG_DIRECTORY_PATH}/319639__Asi_Ayalon_-_Dog_Days_Of_Summer_-_We_Won_t_Survive_-_220204_-_INT_-_X_-_2444_.mp3`,
    songAuthor: 'Assaf Ayalon'
  },
  {
    songName: 'Kerosene Creek',
    songUrl: `${SONG_DIRECTORY_PATH}/441572__Casey_Parnell_-_Kerosene_Creek_-_131220_-_EXT_-_X_-_2444_.mp3`,
    songAuthor: 'Casey Parnell'
  },
  {
    songName: 'Kingdom',
    songUrl: `${SONG_DIRECTORY_PATH}/442762__Andrew_Word_-_Kingdom_-_151220_-_EXT_-_X_-_2444.mp3`,
    songAuthor: 'Andrew Word'
  },
  {
    songName: 'Swamp Shack',
    songUrl: `${SONG_DIRECTORY_PATH}/420835__James_Paul_Mitchell_-_Swamp_Shack_-_261020_-_EXT_-_X_-_2444.mp3`,
    songAuthor: 'James Paul Mitchell'
  },
  {
    songName: 'Do You Wanna Know?',
    songUrl: `${SONG_DIRECTORY_PATH}/429545__428853_Asaf_Azoulay_-_Rockorona_-_Track_2_-_AO-000107-_2_-_Master_V2_-_111120_-_BOV_-_ORG_-_2444.mp3`,
    songAuthor: 'Ace'
  },
  {
    songName: 'Investigation',
    songUrl: `${SONG_DIRECTORY_PATH}/343081__Max_H_-_Inverstigation_-_040620_-_EXT_-_X_-_2444.mp3`,
    songAuthor: 'Max H.'
  },
  {
    songName: 'All Good Vibes',
    songUrl: `${SONG_DIRECTORY_PATH}/57467_01_-_All_Good_Vibes_(16-44.1).mp3`,
    songAuthor: 'Rafi B. Levy'
  },
  {
    songName: '100 Stars Vanished',
    songUrl: `${SONG_DIRECTORY_PATH}/419132__Beneath_the_Mountain_-_100_Stars_Vanished_-_221020_-_EXT_-_X_-_2444.mp3`,
    songAuthor: 'Beneath the Mountain'
  },
  {
    songName: 'Lucky Me',
    songUrl: `${SONG_DIRECTORY_PATH}/378997__378191_Ori_Russo_ft_Ofrin_-_Lucky_-_Free_Lucky_-_AO-000020-1_-_Master_-_BOV_-_260720_-_ORG_-_2444.mp3`,
    songAuthor: 'Ofrin'
  },
  {
    songName: 'Not Everything Not Yet',
    songUrl: `${SONG_DIRECTORY_PATH}/35996_07_-_Not_Everything_Not_Yet_(16-44.1).mp3`,
    songAuthor: 'Glories'
  },
  {
    songName: 'Save Me (ft. Katie Carlene)',
    songUrl: `${SONG_DIRECTORY_PATH}/384335__Kiba_-_Save_Me_(ft._Katie_Carlene)_-_240820_-_EXT_-_X_-_2444_.mp3`,
    songAuthor: 'Kiba'
  },
  {
    songName: 'We',
    songUrl: `${SONG_DIRECTORY_PATH}/82638_03_-_We_-_Master_(16-44.1).mp3`,
    songAuthor: 'Fjodor'
  },
  {
    songName: 'See You Again',
    songUrl: `${SONG_DIRECTORY_PATH}/441467__Michael_FK_-_See_you_again_-_131220_-_EXT_-_X_-_2444.mp3`,
    songAuthor: 'Michael FK'
  },
  {
    songName: 'Discovery',
    songUrl: `${SONG_DIRECTORY_PATH}/discovery by delnova Artlist.mp3`,
    songAuthor: 'DELNOVA'
  },
  {
    songName: 'Lever de la Lune',
    songUrl: `${SONG_DIRECTORY_PATH}/115764_Dan_Zeitune_feat._Loren_Noyman_-_Lever_de_la_lune_-_Master_(16-44.1)RG.mp3`,
    songAuthor: 'Dan Zeitune'
  },
  {
    songName: 'Hit the Beat',
    songUrl: `${SONG_DIRECTORY_PATH}/427998__427515_Ran_Loya_-_Make_More_Room_For_The_Kids_-_Hit_the_beat_-_AO-000082_-_2_-_Master_V3_-_031120_-_BOV_-_ORG_-_2444.mp3`,
    songAuthor: 'IamDayLight'
  },
  {
    songName: 'The World Inside',
    songUrl: `${SONG_DIRECTORY_PATH}/86638_09_-_The_World_Inside_(16-44.1).mp3`,
    songAuthor: 'Rodello\'s Machine'
  },
  {
    songName: 'American Hope',
    songUrl: `${SONG_DIRECTORY_PATH}/373250__372310_Dor_Friedman_-_Cinematic_Orchestra_-_Track_1_-_Master_V2_-_190720_-_INT_-_2444.mp3`,
    songAuthor: 'Dor Friedman'
  },
  {
    songName: 'Can You Hear the Sleigh Bells',
    songUrl: `${SONG_DIRECTORY_PATH}/427064__426833_Ofer_Koren_-_Its_Christmas__-_Sleigh_Bells_-_AO-000087_-_2_-_Master_V2_-_BOV_-_091120_-_ORG_-_2444.mp3`,
    songAuthor: 'Ofer Koren'
  },
  {
    songName: 'Why Do Flowers Mean Love?',
    songUrl: `${SONG_DIRECTORY_PATH}/417514__Yali_Blank_-_Why_Do_Flowers_Mean_Love__-_201020_-_EXT_-_X_-_2444.mp3`,
    songAuthor: 'Yali Blank'
  },
  {
    songName: 'Music',
    songUrl: `${SONG_DIRECTORY_PATH}/music by assaf-ayalon Artlist.mp3`,
    songAuthor: 'Assaf Ayalon'
  },
  {
    songName: 'Spacey Curry',
    songUrl: `${SONG_DIRECTORY_PATH}/355909__355150_Peter_Spacey_(Omer_Luz)_-_Cosmic_Drive-In_-_Spacey_Curry_-_Master_V2_-_040620_-_INT_-_2444.mp3`,
    songAuthor: 'Peter Spacey'
  },
  {
    songName: 'Home Flower',
    songUrl: `${SONG_DIRECTORY_PATH}/421683__419807_Ben_Goldstein_-_Home_Flower_-_Master_-_BOV_-_191020_-_INT_-_2444.mp3`,
    songAuthor: 'Ben Goldstein'
  },
  {
    songName: 'Hot for a Min',
    songUrl: `${SONG_DIRECTORY_PATH}/421786__420769_Captain_Qubz_-_Loading_Zone_-_HotForAMin-A0-000100-4-MasterV3-191020-BOV-ORG-2444.mp3`,
    songAuthor: 'Captain Qubz'
  },
  {
    songName: 'Northwest Wind',
    songUrl: `${SONG_DIRECTORY_PATH}/429414__Hans_Johnson_-_Northwest_Wind_-_161120_-_EXT_-_X_-_2444.mp3`,
    songAuthor: 'Hans Johnson'
  },
  {
    songName: 'The Bold and Brave',
    songUrl: `${SONG_DIRECTORY_PATH}/374015__Matan_Gov_Ari_-_The_Bold_And_Brave_-_Master_V2_-_150720_-_INT_-_X_-_2444.mp3`,
    songAuthor: 'Matan Gov Ari'
  },
  {
    songName: 'Breaking of Glass',
    songUrl: `${SONG_DIRECTORY_PATH}/424109__423388_Itamar_Ben_Yakir_-_Gypsy_Wedding_-_Breaking_Of_Glass_-_AO_000098-1-_Master_V3_-_191020_-_BOV_-_ORG_-_2444.mp3`,
    songAuthor: 'IBY'
  },
  {
    songName: 'Ocean in Motion',
    songUrl: `${SONG_DIRECTORY_PATH}/439791__Eldar_Kedem_-_Dream_On_-_AO-000131_-_Master_v2_-_BOV_-_011220_-_2444.mp3`,
    songAuthor: 'Eldar Kedem'
  },
  {
    songName: 'Watercolour Beach',
    songUrl: `${SONG_DIRECTORY_PATH}/433684__Kitrano_-_Watercolour_Beach_-_261120_-_EXT_-_X_-_2444___.mp3`,
    songAuthor: 'Kitrano'
  },
  {
    songName: 'Oceanside',
    songUrl: `${SONG_DIRECTORY_PATH}/434022__Kicktracks_-_Oceanside_-_291120_-_EXT_-_X_-_2444.mp3`,
    songAuthor: 'Kicktracks'
  },
  {
    songName: 'Caves And Mr. Budkov',
    songUrl: `${SONG_DIRECTORY_PATH}/435654__Vox_Portent_-_Caves_And_Mr._Budkov_-_011220_-_EXT_-_X_-_2444_.mp3`,
    songAuthor: 'Vox Portent'
  },
  {
    songName: 'Hawaii Whykiki',
    songUrl: `${SONG_DIRECTORY_PATH}/398269__396897_Ofer_Koren_-_Down_Old_Country_Lane_-_Hawaii_50_-_AO-000040_-_5_-_Master_v2_-_BOV_-_020920_-_ORG_-_2444.mp3`,
    songAuthor: 'Ofer Koren'
  },
  {
    songName: 'Hell Yeah',
    songUrl: `${SONG_DIRECTORY_PATH}/84764_Hell_Yeah_-_Master_(16-44.1).mp3`,
    songAuthor: 'Ride Free'
  },
  {
    songName: 'Lila',
    songUrl: `${SONG_DIRECTORY_PATH}/87661_Lila_(16-44.1).mp3`,
    songAuthor: 'Rodello\'s Machine'
  },
  {
    songName: 'Copper Queen',
    songUrl: `${SONG_DIRECTORY_PATH}/248646__01_-_Copper_Queen_-_Master_(24-44.1)RG.mp3`,
    songAuthor: 'Amparo'
  },
  {
    songName: 'Toxic',
    songUrl: `${SONG_DIRECTORY_PATH}/29270_Toxic_-_Master_(16-44.1).mp3`,
    songAuthor: 'Asi-C'
  },
  {
    songName: 'Invincible',
    songUrl: `${SONG_DIRECTORY_PATH}/351017__350557_Orel_Tamuz_-_Busy_Streets_-_Invincable_-_Master_V2_-_090620_-_INT_-_2444.mp3`,
    songAuthor: 'ORKAS'
  },
  {
    songName: 'Time to Move',
    songUrl: `${SONG_DIRECTORY_PATH}/time-to-move by veshza Artlist.mp3`,
    songAuthor: 'VESHZA'
  },
  {
    songName: 'Deep Horizon',
    songUrl: `${SONG_DIRECTORY_PATH}/346353__345812_Raz_Burg_-_Stay_On_The_Move_-_Deep_Horizon_-_250520_-_MASTER_-_INT_-_X_-_2444.mp3`,
    songAuthor: 'Raz Burg'
  },
  {
    songName: 'Sneak Peek',
    songUrl: `${SONG_DIRECTORY_PATH}/366786__365904_Alon_Peretz_-_Sneak_Peek_-_Master_V1.2_-_290620_-_INT_-_X_-_2444.mp3`,
    songAuthor: 'Alon Peretz'
  },
  {
    songName: 'Believer',
    songUrl: `${SONG_DIRECTORY_PATH}/believer by iamdaylight Artlist.mp3`,
    songAuthor: 'IamDayLight'
  },
  {
    songName: 'Curry',
    songUrl: `${SONG_DIRECTORY_PATH}/426338__Vic_Sage_-_Curry_-_091120_-_EXT_-_X_-_2444.mp3`,
    songAuthor: 'Vic Sage'
  },
  {
    songName: 'I Promise',
    songUrl: `${SONG_DIRECTORY_PATH}/i-promise by ian-post Artlist.mp3`,
    songAuthor: 'Ian Post'
  },
  {
    songName: 'Seven Wonders',
    songUrl: `${SONG_DIRECTORY_PATH}/seven-wonders by ziv-moran Artlist.mp3`,
    songAuthor: 'Ziv Moran'
  },
  {
    songName: 'Chartreux Noir',
    songUrl: `${SONG_DIRECTORY_PATH}/427866__Bob_Hart_-_Chartreux_Noir_-_121120_-_EXT_-_X_-_2444.mp3`,
    songAuthor: 'Bob Hart'
  },
  {
    songName: 'After the Movie (by Plaid) - Remix Version',
    songUrl: `${SONG_DIRECTORY_PATH}/350726__Ricardo_Tobar_-_After_The_Movie_(Plaid_Remix)_-_150620_-_EXT_-_X_-_2444.mp3`,
    songAuthor: 'Ricardo Tobar'
  },
  {
    songName: 'Mystery',
    songUrl: `${SONG_DIRECTORY_PATH}/414965__sod_ven_-_Mystery_-_151020_-_EXT_-_X_-_2444.mp3`,
    songAuthor: 'sød ven'
  },
  {
    songName: 'Through the Sunset',
    songUrl: `${SONG_DIRECTORY_PATH}/371213__Seth_Parson_-_Through_The_Sunset.mp3`,
    songAuthor: 'Seth Parson'
  },
  {
    songName: 'Remember Me',
    songUrl: `${SONG_DIRECTORY_PATH}/315593__MARLOE._-_Remember_Me_-_280420_-_EXT_-_X_-_2444.mp3`,
    songAuthor: 'MARLOE.'
  },
  {
    songName: 'Beyond the Stories',
    songUrl: `${SONG_DIRECTORY_PATH}/beyond-the-stories by ian-post Artlist.mp3`,
    songAuthor: 'Ian Post'
  },
  {
    songName: 'Where I Find Rest',
    songUrl: `${SONG_DIRECTORY_PATH}/where-i-find-rest by sun-wash Artlist.mp3`,
    songAuthor: 'Sun Wash'
  },
  
]