import { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Typography } from '../constant/GlobalTypography'

// ask seniors to fix this

const SongTimeSliderWrap = styled.div`
`

const SongTimeIndicator = styled.div`
  position: relative;
  margin: 5px 16px 16px 16px;
  display: flex;
  color: #B9BABE;
  ${Typography.body2}
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
`

const SongCurrentTime = styled.div`
  flex-grow: 1;
`

const SongRemainTime = styled.div`
`

const SliderBar = styled.input`
  position: relative;
  left: -2px;
  margin-top: 25px;
  // margin-bottom: 35px;
  -webkit-appearance: none;
  appearance: none;
  width: 100%; 
  height: 5px; 
  outline: none; 
  opacity: 0.7;  
  background-color: #F2F2F2;
  background: linear-gradient(to right, #DB2E8A 0%, #DB2E8A var(--seek-before-width), #F2F2F2 var(--seek-before-width));
  
  &:hover {
    cursor: pointer;
  }

  &::-webkit-slider-thumb {
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #131313;
    cursor: pointer;
    z-index: 1;
  }

  &::-moz-range-thumb {
    width: 10px;
    height: 10px; 
    border-radius: 50%;
    background: #131313;
    cursor: pointer;
  }
`

const SongTimeSlider = (props: Props) => {

  const { audioRef, isPlaying } = props
  
  const [audioDuration, setAudioDuration] = useState<number|null>(audioRef.current?.duration)
  const isAnimation = useRef<boolean>(false) // control requestAnimationFrame
  const rAF = useRef<number|null>(null) // requestAnimationFrame
  
  const inputRef = useRef<HTMLInputElement|null>(null) // ref for input[type="range"]
  const songCurrentTimeRef = useRef<HTMLDivElement|null>(null) // ref for current Time display
  const songRemainTimeRef = useRef<HTMLDivElement|null>(null) // ref for remain time display

  const convertCurrentTime = (num_seconds: number) => { // convert format ss -> mm:ss
    const minute = Math.floor(Math.floor(num_seconds) / 60)
    const secs = Math.floor(num_seconds) % 60
    return (minute < 10 ? `0${minute}:` : `${minute}:`) + (secs < 10 ? `0${secs}` : `${secs}`)
  }
  const convertRemainTime = (num_seconds: number) => {
    const remTime = Math.floor(audioDuration! - Math.floor(num_seconds))
    const minute = Math.floor(remTime / 60)
    const secs = remTime % 60
    return (minute < 10 ? `-0${minute}:` : `${minute}:`) + (secs < 10 ? `0${secs}` : `${secs}`)
  }

  const whilePlaying = () => { // recursive func for requestAnimation Frame (bugs source :<)
    const newTime = audioRef.current?.currentTime
    const newSeekWidth = 100*((newTime as unknown) as number)/audioDuration!
    if(inputRef.current != null) {
      inputRef.current.value = newTime
      songCurrentTimeRef.current!.textContent = convertCurrentTime(newTime)
      songRemainTimeRef.current!.textContent = convertRemainTime(newTime) 
    }
    inputRef.current?.style.setProperty('--seek-before-width', `${newSeekWidth}%`)
    // console.log('animation running')
    if(isAnimation.current) {
      rAF.current = requestAnimationFrame(whilePlaying)
    }
  }

  const instantTimeUpdate = () => { // update time after audio seeking
    isAnimation.current = true
    requestAnimationFrame(whilePlaying)
    isAnimation.current = false
  }

  const onChangeHandler = () => { // handle change after user stop seeking
    audioRef.current!.currentTime = inputRef.current?.value
    instantTimeUpdate()
    if(isPlaying && !isAnimation.current) {
      isAnimation.current = true
      requestAnimationFrame(whilePlaying)
    }
  }

  useEffect(() => { // run or stop AnimationFrame based on "isPlaying"
    if(!isPlaying && isAnimation.current) {
      isAnimation.current = false
    } else if(isPlaying && !isAnimation.current){
      isAnimation.current = true
      requestAnimationFrame(whilePlaying)
    }
  }, [isPlaying])

  useEffect(() => { // update new duration for audio seeker
    inputRef.current!.max = `${audioRef.current!.duration}`
    setAudioDuration(audioRef.current!.duration)
  }, [audioRef.current!.duration])

  useEffect(() => { // run once after first render
    inputRef.current!.max = `${audioRef.current!.duration}`
    const updOnInput = () => {
      if(!audioRef.current?.paused && isAnimation.current) {
        isAnimation.current = false
      }
    }

    inputRef.current!.addEventListener('input', updOnInput)

    return () => {
      if(isAnimation.current)  {
        isAnimation.current = false
      }
      if(inputRef.current != null) {
        inputRef.current!.removeEventListener('input', updOnInput)
        // cancelAnimationFrame(rAF)
        isAnimation.current = false
      }
    }
  }, [])

  return (
    <SongTimeSliderWrap>
      <SliderBar
        ref={inputRef}
        type='range'
        value={audioRef.current!.currentTime}
        step={0.00001}
        onChange={onChangeHandler}
      />
      <SongTimeIndicator>
        <SongCurrentTime ref={songCurrentTimeRef}></SongCurrentTime>
        <SongRemainTime ref={songRemainTimeRef}></SongRemainTime>
      </SongTimeIndicator>
    </SongTimeSliderWrap>
  )
}
 
type Props = {
  audioRef: any, // :>
  isPlaying: boolean
}    
 
export default SongTimeSlider