import { SubTopArea, SoundLibraryContent } from '../components'
import { DefaultInnerwrap } from '../constant/GlobalStyles'
import { SoundEventDetection } from '../constant/Data'
import Layout from '../layout/Layout'

const SoundLibrary = (props: Props) => {
  
  return (
    <Layout>
      <DefaultInnerwrap>
        <SubTopArea
            isOpenDemo={false} 
            pageData={SoundEventDetection.Contents}
        />
        <SoundLibraryContent/>
      </DefaultInnerwrap>
    </Layout>
  )
}

type Props = {
  pageData: any 
}

export default SoundLibrary 