import styled from 'styled-components'
import { ColorTheme, HeightMargin } from '../constant/GlobalStyles'
import { Typography } from '../constant/GlobalTypography'
import { FileUploading } from '../components/FileUploading'
import { useRef, useState } from 'react'
import { ICONS_URLS } from '../constant/Data'


const UploadingIcons = styled.img``
const FileInput = styled.div`
  position: relative;

  > input {
    visibility: hidden;
  }
  > label {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -50px;
    ${Typography.h5 as any}
    color: ${ColorTheme.Blue};

    &:hover {
      cursor: pointer;
    }
  }
`
const DragText = styled.p`
  ${Typography.h5 as any}
`


const DragDropZone = (props:Props) => {
  const {
    changeOrder, 
    currentOrder, 
    serviceList, 
    functionName, 
    setResultsData, 
    setModalOpen, 
    setErrorCode, 
    setLoadingState
  } = props
  const InputComponent = useRef<HTMLInputElement>(null);
  const [DragEnter, setDragEnter] = useState<boolean>(false);

  const FileDropZone = styled.div<{DragState:boolean}>`
    height: ${HeightMargin.Uploading_Height};
    border: 1px dashed ${props => (props.DragState? ColorTheme.Green : ColorTheme.GrayLight4)};
    border-radius: 10px;
    ${Typography.h5 as any};
    text-align: center;
    padding-top: 60px;
    cursor: pointer;
  `

  const fileSearch = async(e:any) => {
    InputComponent.current?.click();
  }

  const goResultsPage = (resultData:any) => {
    setResultsData(resultData);
    let result  = currentOrder + 1
    changeOrder(result)
  }

  const PushToServer = async(files:any) => {
    let resultData:any
    try {
      setLoadingState(true)
      resultData = await FileUploading(functionName,files[0],serviceList);
      // console.log(resultData);
      if(resultData === 0) {
        setLoadingState(false)
        setModalOpen(true)
        setErrorCode(resultData)
      } else if (resultData === 1) {
        setLoadingState(false)
        setModalOpen(true)
        setErrorCode(resultData)
      } else {
        goResultsPage(resultData)
      }
      
    } catch {

    }
  }

  const dragOver = (e:any) => {
    e.preventDefault();
  }

  const dragEnter = (e:any) => {
    e.preventDefault();
    setDragEnter(true);
  }

  const dragLeave = (e:any) => {
    e.preventDefault();
  }

  const fileDrop = async(e:any) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    PushToServer(files);
  }

  const onFileChange = (e:any) => {
    const files = e.target.files;
    PushToServer(files);
  }

  return(
    <FileDropZone
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={fileDrop}
      onClick={fileSearch}
      DragState={DragEnter}>
      <UploadingIcons src={ICONS_URLS.uploading} alt="uploading icon"/>
      <DragText>Drag and Drop to Upload</DragText>
      <FileInput>
        <label>or Browse</label>
        <input type="file" ref={InputComponent} onChange={onFileChange}/>
      </FileInput>
    </FileDropZone>
  )
}

type Props = {
  changeResults?: any
  changeOrder?: any
  currentOrder?: any
  serviceList?: any
  functionName?: string
  setResultsData?: any
  setModalOpen?: any
  setErrorCode?: any
  setLoadingState?: any
}

export default DragDropZone;
