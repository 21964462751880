import styled from 'styled-components'
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { ColorTheme } from '../constant/GlobalStyles'
import { Typography } from '../constant/GlobalTypography'

const PrecautionBox = styled.div`
  background-color: ${ColorTheme.Yellow};
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
`
const Contents = styled.ul`
  padding: 20px 0;
  >li {
    display: flex;
    svg, span {
      vertical-align: middle;
    }
    svg {
      transform: rotate(180deg);
    }
    span {
      ${Typography.body2};
      padding-left: 10px;
      text-size-adjust: 100%; 
      -ms-text-size-adjust: 100%; 
      -moz-text-size-adjust: 100%; 
      -webkit-text-size-adjust: 100%;
      b {
        font-weight: 600;
      }
    }
  }
`

const Precaution = (props:Props) => {

  const {pageData} = props

  return(
    <PrecautionBox>
      <Contents>
        {
          pageData.map((item:any, index:number) => (
            <li key={index}>
              <ErrorOutline/>
              <span
                dangerouslySetInnerHTML={{
                    __html: item,
                }}
              />
            </li>
          ))
        }
        
      </Contents>
    </PrecautionBox>
  )

}

type Props = {
  pageData: any
}


export default Precaution;