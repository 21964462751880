import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Typography } from "../constant/GlobalTypography";
import { ColorTheme } from "../constant/GlobalStyles";

const Label = styled.label`
  ${Typography.body2 as any}
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  margin-left: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover input ~ span {
    background-color: #ccc;
  }
`;
const InputCheck = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &[name="true"] ~ span:after {
    display: block;
  }
`;
const CheckMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 26px;
  background-color: ${ColorTheme.GrayLight7};
  border-radius: 2px;
  transition: all 0.25s;

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 6px;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    background-color: ${ColorTheme.Pink};
  }
`;

const CheckBoxItem = (props: Props) => {
  const { label, value, ServiceList, setServiceList } = props;

  const clickChange = (e: any) => {
    const checkedValue = e.currentTarget.value;
    let soundList = [...ServiceList];
    const IsHere: any = soundList.indexOf(checkedValue);
    const Check = (item: any) => {
      return item !== checkedValue;
    };

    if (IsHere === -1) {
      soundList = [...ServiceList, checkedValue];
      setServiceList(soundList);
    } else {
      soundList = soundList.filter(Check);
      setServiceList(soundList);
    }
  };

  return (
    <>
      <Label>
        <InputCheck type="checkbox" value={value} onChange={clickChange} />
        <CheckMark />
        {label}
      </Label>
    </>
  );
};

type Props = {
  label: string;
  value: string;
  ServiceList: any;
  setServiceList: any;
};

export default CheckBoxItem;
