import { BrowserRouter as Router, Route } from "react-router-dom";
import Main from "../pages";
import SoundLibrary from "../pages/sound-library";
import MusicList from "../pages/music-list";
import sound_event_detection from "../pages/sound-event-detection";
import music_content_analysis from "../pages/music-content-analysis";
import music_identification from "../pages/music-identification";
import similar_music_recommendation from "../pages/similar-music-recommendation";
import speaker_identification from "../pages/speaker-identification";
import GlobalStyles from "../constant/GlobalStyles";

const Root = () => {
  return (
    <>
      <Router>
        <Route path="/" exact component={Main} />
        <Route
          path="/sound-event-detection"
          exact
          component={sound_event_detection}
        />
        <Route path="/sound-library" exact component={SoundLibrary} />
        <Route
          path="/music-content-analysis"
          exact
          component={music_content_analysis}
        />
        <Route
          path="/music-identification"
          exact
          component={music_identification}
        />
        <Route path="/music-list" exact component={MusicList} />
        <Route
          path="/similar-music-recommendation"
          exact
          component={similar_music_recommendation}
        />
        <Route
          path="/speaker-identification"
          exact
          component={speaker_identification}
        />
      </Router>
      <GlobalStyles />
    </>
  );
};

export default Root;
