import styled from "styled-components";
import { Typography } from "../constant/GlobalTypography";
import { ColorTheme, HeightMargin } from "../constant/GlobalStyles";
import { useState, useEffect } from "react";
import { RecordUploading } from "./FileUploading";
import { ICONS_URLS } from "../constant/Data";

const AudioArea = styled.div<{ IsRecording: boolean }>`
  height: ${HeightMargin.Uploading_Height};
  border: 1px
    ${(props) =>
      props.IsRecording
        ? ColorTheme.RedLight + " solid"
        : ColorTheme.GrayLight4 + " dashed"};
  border-radius: 10px;
`;
const Description = styled.p`
  padding: 30px 0 50px;
  text-align: center;
  ${Typography.body3 as any};
  color: ${ColorTheme.GrayLight4};
`;
const ButtonArea = styled.div`
  text-align: center;
`;
const RecordingButton = styled.p`
  display: inline-block;
  vertical-align: middle;
  width: 175px;
  box-sizing: border-box;
  padding: 15px 30px;
  border: 2px solid ${ColorTheme.Red};
  border-radius: 100px;
  background-color: ${ColorTheme.RedLight};
  text-align: center;
  cursor: pointer;
  > img {
    display: inline-block;
    padding-right: 10px;
    vertical-align: middle;
  }
  > span {
    display: inline-block;
    vertical-align: middle;
    line-height: 53px;
    ${Typography.button as any}
    color: ${ColorTheme.Red};
  }
`;
const TimerText = styled.span`
  padding-left: 20px;
  vertical-align: middle;
  display: inline-block;
  ${Typography.h3 as any}
  color: ${ColorTheme.GrayLight3};
`;

let AudioRecorder: any;
let CurrentStream: any;
let Timer: any;
let IsPrev: number;

// Stop recording
export const stopRecorder = () => {
  clearInterval(Timer);
  AudioRecorder?.stop();
  CurrentStream?.getAudioTracks()[0].stop();
  // console.log(Recording)
  // console.log('stop')
};

export const PrevStopRecorder = () => {
  IsPrev = 1;
  stopRecorder();
};

const RecordingZone = (props: Props) => {
  const {
    changeOrder,
    currentOrder,
    serviceList,
    functionName,
    setResultsData,
    setModalOpen,
    setErrorCode,
    setLoadingState,
  } = props;

  // control the event
  const [Recording, setRecording] = useState<boolean>(false);
  const [RecordingTime, setRecordingTime] = useState<number>(0);

  const goResultsPage = (resultData: any) => {
    setResultsData(resultData);
    let result = currentOrder + 1;
    changeOrder(result);
  };

  const changeEvent = () => {
    if (Recording) {
      setRecording(false);
      stopRecorder();
    } else {
      setRecording(true);
      startRecorder();
    }
  };

  useEffect(() => {
    if (RecordingTime === 20) {
      clearInterval(Timer);
      stopRecorder();
    }
  }, [RecordingTime]);

  // Start Recording
  let audioChunks: any[] = [];
  const startRecorder = () => {
    // get audio from user
    navigator.mediaDevices
      .getUserMedia({
        audio: {
          autoGainControl: false,
          echoCancellation: false,
          noiseSuppression: false,
          sampleRate: 48000,
        },
      })
      .then((stream) => {
        AudioRecorder = new MediaRecorder(stream);
        CurrentStream = stream;
        AudioRecorder.start();

        // push audio data to audioChunks & convert audio data to audio data blob
        AudioRecorder.addEventListener("dataavailable", (e: any) =>
          audioChunks.push(e.data)
        );
        AudioRecorder.addEventListener("stop", () => makeFile(audioChunks));

        // timer
        Timer = setInterval(() => {
          setRecordingTime((RecordingTime) => RecordingTime + 1);
          // console.log('working timer');
        }, 1000);
      })
      .catch(() => {
        setModalOpen(true);
        setErrorCode(2);
      });
  };

  const PushToServer = async (audioBlob: any, wavfilename: any) => {
    let resultData: any;
    try {
      setLoadingState(true);
      resultData = await RecordUploading(
        functionName,
        audioBlob,
        wavfilename,
        serviceList
      );
      // console.log(resultData);
      if (resultData === 0) {
        setLoadingState(false);
        setModalOpen(true);
        setErrorCode(resultData);
      } else {
        goResultsPage(resultData);
      }
    } catch {}
  };

  // making file and push to server
  const makeFile = (chunk: any) => {
    if (IsPrev === 1) {
      // console.log('stop here')
    } else {
      const audioBlob = new Blob(chunk);
      const wavfilename = new Date().toISOString() + ".wav";
      PushToServer(audioBlob, wavfilename);
    }
    IsPrev = 0;
  };

  return (
    <AudioArea IsRecording={Recording}>
      <Description>Start recording by clicking the record button.</Description>
      <ButtonArea>
        {!Recording ? (
          <RecordingButton onClick={changeEvent}>
            <img src={ICONS_URLS.recording} alt="icons" />
            <span>RECORD</span>
          </RecordingButton>
        ) : (
          <>
            <RecordingButton onClick={changeEvent}>
              <img src={ICONS_URLS.stop} alt="icons" />
              <span>STOP</span>
            </RecordingButton>
            <TimerText>
              00:
              {RecordingTime.toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}
            </TimerText>
          </>
        )}
      </ButtonArea>
    </AudioArea>
  );
};

type Props = {
  changeOrder?: any;
  currentOrder?: any;
  serviceList?: any;
  functionName?: any;
  setResultsData?: any;
  setModalOpen?: any;
  setErrorCode?: any;
  setLoadingState?: any;
};

export default RecordingZone;
