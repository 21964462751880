import {} from '.'

export const Category = {
    cochlSense: 'Cochl.Sense',
    cochlMusic: 'Cochl.Music',
    cochlSpeech: 'Cochl.Speech'
}

export const ProcessStep = {
    SelectClasses : 'SelectClasses',
    QRCode: 'QRCode',
    Methods : 'Methods',
    Uploading: 'Uploading',
    Recording: 'Recording',
    Results: 'Results'
}

export const ResultsCategory = {
    SoundGraph : 'Sound/Graph',
    SoundAnalysis: 'Music_Analysis',
    SongName: 'SongName',
    SongNameID: 'SongNameID',
    SongNameRecommend: 'SongNameRecommend',
    VoiceName: 'VoiceName'
}

