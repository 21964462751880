import styled from 'styled-components'

const LoadingArea = styled.div<{showing : boolean}>`
	position: absolute;
	height: 250px;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.7);
	z-index: 100;
	display: ${props => props.showing ? 'block' : 'none'};
`

const TextArea = styled.div`
	position: relative;
	top: 100px;

	.bubblingG {
	text-align: center;
	width:150px;
	height:49px;
	margin: auto;
}
.bubblingG span {
	display: inline-block;
	vertical-align: middle;
	width: 10px;
	height: 10px;
	margin: 24px auto;
	margin-right: 10px;
	> &:last-child {
		margin-right: 0;
	}
	background: rgb(0,0,0);
	border-radius: 49px;
		-o-border-radius: 49px;
		-ms-border-radius: 49px;
		-webkit-border-radius: 49px;
		-moz-border-radius: 49px;
	animation: bubblingG 1.5s infinite alternate;
		-o-animation: bubblingG 1.5s infinite alternate;
		-ms-animation: bubblingG 1.5s infinite alternate;
		-webkit-animation: bubblingG 1.5s infinite alternate;
		-moz-animation: bubblingG 1.5s infinite alternate;
}

#bubblingG_1 {
	animation-delay: 0s;
		-o-animation-delay: 0s;
		-ms-animation-delay: 0s;
		-webkit-animation-delay: 0s;
		-moz-animation-delay: 0s;
}

#bubblingG_2 {
	animation-delay: 0.45s;
		-o-animation-delay: 0.45s;
		-ms-animation-delay: 0.45s;
		-webkit-animation-delay: 0.45s;
		-moz-animation-delay: 0.45s;
}

#bubblingG_3 {
	animation-delay: 0.9s;
		-o-animation-delay: 0.9s;
		-ms-animation-delay: 0.9s;
		-webkit-animation-delay: 0.9s;
		-moz-animation-delay: 0.9s;
}



@keyframes bubblingG {
	0% {
		width: 10px;
		height: 10px;
		background-color:rgb(0,0,0);
		transform: translateY(0);
	}

	100% {
		width: 23px;
		height: 23px;
		background-color:rgb(255,255,255);
		transform: translateY(-20px);
	}
}

@-o-keyframes bubblingG {
	0% {
		width: 10px;
		height: 10px;
		background-color:rgb(0,0,0);
		-o-transform: translateY(0);
	}

	100% {
		width: 23px;
		height: 23px;
		background-color:rgb(255,255,255);
		-o-transform: translateY(-20px);
	}
}

@-ms-keyframes bubblingG {
	0% {
		width: 10px;
		height: 10px;
		background-color:rgb(0,0,0);
		-ms-transform: translateY(0);
	}

	100% {
		width: 23px;
		height: 23px;
		background-color:rgb(255,255,255);
		-ms-transform: translateY(-20px);
	}
}

@-webkit-keyframes bubblingG {
	0% {
		width: 10px;
		height: 10px;
		background-color:rgb(0,0,0);
		-webkit-transform: translateY(0);
	}

	100% {
		width: 23px;
		height: 23px;
		background-color:rgb(255,255,255);
		-webkit-transform: translateY(-20px);
	}
}

@-moz-keyframes bubblingG {
	0% {
		width: 10px;
		height: 10px;
		background-color:rgb(0,0,0);
		-moz-transform: translateY(0);
	}

	100% {
		width: 23px;
		height: 23px;
		background-color:rgb(255,255,255);
		-moz-transform: translateY(-20px);
	}
}
`

export const Loading = (props:Props) => {
	const {state} = props
  return (
    <LoadingArea showing={state}>
			<TextArea>
			<div className="bubblingG">
				<span id="bubblingG_1">
				</span>
				<span id="bubblingG_2">
				</span>
				<span id="bubblingG_3">
				</span>
			</div>
			</TextArea>
    </LoadingArea>
  )
}

type Props = {
	state: boolean
}

export default Loading