import { SongTitle } from '../constant/GlobalStyles'


const SongNameID = (props:Props) => {

  const {resultsData} = props

  return(
    <>
      <SongTitle>
        <h3>{resultsData.data.result.song}</h3>
        <span>{resultsData.data.result.artist}</span>
      </SongTitle>
    </>
  )

}

type Props = {
  resultsData:any
}


export default SongNameID;