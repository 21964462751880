import { IMAGE_DIRECTORY_PATH, SoundEventDetection } from ".";

const ROOT_SOUND_DIRECTORY = `${IMAGE_DIRECTORY_PATH.labs}/SampleSounds`;

export type SubDirectoryType = {
  sub_display_name: string;
  sub_directory_path: string;
  items: string[];
};

export type SoundDirectoryPathType = {
  display_name: string;
  directory_path: string;
  sub_directories: SubDirectoryType[];
};

export const SOUND_DIRECTORY_PATH = {
  emergency_detection: `${ROOT_SOUND_DIRECTORY}/Emergency_Detection`,
  human_interaction: `${ROOT_SOUND_DIRECTORY}/Human_Interaction`,
  human_status: `${ROOT_SOUND_DIRECTORY}/Human_Status`,
  home_context: `${ROOT_SOUND_DIRECTORY}/Home_Context`,
  human_voice: `${ROOT_SOUND_DIRECTORY}/Human_Voice`,
  traffic: `${ROOT_SOUND_DIRECTORY}/Traffic`,
  etc: `${ROOT_SOUND_DIRECTORY}/Etc`,
};

export const SOUND_DIRECTORY = [
  {
    display_name: SoundEventDetection.Classes[0].category,
    directory_path: SOUND_DIRECTORY_PATH.emergency_detection,
    sub_directories: [
      {
        sub_display_name: SoundEventDetection.Classes[0].sounds[0][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.emergency_detection}/Civil_defense_siren`,
        items: [
          "67_Civil_siren_1468775-cut.mp3",
          "68_Civil_siren_1468759-cut.mp3",
          "69_Civil_siren_1468996-1.7-11-segment.mp3",
          "70_Civil_siren_1469112-cut.mp3",
          "Siren_sampleE.mp3"
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[0].sounds[1][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.emergency_detection}/Emergency_vehicle_siren`,
        items: [
          "66_Emergency_vehicle_siren_528915.mp3",
          "Siren_sampleA-1.mp3",
          "Siren_sampleB.mp3",
          "Siren_sampleC.mp3",
          "Siren_sampleD-1.mp3"
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[0].sounds[2][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.emergency_detection}/Fire_smoke_alarm`,
        items: [
          "Fire_smoke_alarm_sampleA-1.mp3",
          "Fire_smoke_alarm_sampleB-1.mp3",
          "Fire_smoke_alarm_sampleC-1.mp3",
          "Fire_smoke_alarm_sampleD-1.mp3",
          "Fire_smoke_alarm_sampleE.mp3",
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[0].sounds[3][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.emergency_detection}/Glass_break`,
        items: [
          "Glass_break_sampleA.mp3",
          "Glass_break_sampleB.mp3",
          "Glass_break_sampleC.mp3",
          "Glass_break_sampleD-1.mp3",
          "Glass_break_sampleE.mp3",
          "Glass_break_sampleF.mp3",
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[0].sounds[4][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.emergency_detection}/Gunshot`,
        items: [
          "Gunshot_sample1.wav",
          "Gunshot_sample2.wav",
          "Gunshot_sample3.wav",
          "Gunshot_sample4-1.mp3",
          "Gunshot_sample5.wav",
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[0].sounds[5][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.emergency_detection}/Scream`,
        items: [
          "Scream_sampleA-1.mp3",
          "Scream_sampleB.wav",
          "Scream_sampleC.wav",
          "Scream_sampleD.wav",
          "Scream_sampleE-1.mp3",
          "Scream_sampleF.wav",
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[0].sounds[6][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.emergency_detection}/Yell`,
        items: [
          "Yell_sampleA.mp3",
          "Yell_sampleB.mp3",
          "Yell_sampleC.mp3",
          "Yell_sampleD.wav",
          "Yell_sampleE.wav",
        ],
      },
    ],
  },
  {
    display_name: SoundEventDetection.Classes[1].category,
    directory_path: SOUND_DIRECTORY_PATH.human_interaction,
    sub_directories: [
      {
        sub_display_name: SoundEventDetection.Classes[1].sounds[0][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.human_interaction}/Double_clap`,
        items: [
          "Double_clap_sample1.wav",
          "Double_clap_sample2.wav",
          "Double_clap_sample3.wav",
          "Double_clap_sample4.wav",
          "Double_clap_sample5.wav",
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[1].sounds[1][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.human_interaction}/Finger_snap`,
        items: [
          "Finger_snap_sample1.wav",
          "Finger_snap_sample2.wav",
          "Finger_snap_sample3.wav",
          "Finger_snap_sample4.wav",
          "Finger_snap_sample5.wav",
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[1].sounds[2][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.human_interaction}/Knock`,
        items: [
          "Knock_sample1.wav",
          "Knock_sample2-1.mp3",
          "Knock_sample3.wav",
          "Knock_sample4.wav",
          "Knock_sample5.wav",
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[1].sounds[3][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.human_interaction}/throat_clear`,
        items: [
          "125403__jasonlon__man-clearing-throat-coughing.mp3",
          "408087__biawinter__clear-throat.mp3",
          "433640__dersuperanton__cough-slightly-clear-throat-1.mp3",
          "447496__florianreichelt__to-clear-one-s-throat.mp3",
          "546751__sukaichae__clear-throat-and-coughs.mp3",
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[1].sounds[4][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.human_interaction}/Tongue_click`,
        items: [
          "56_Tongue_click_511991-8-10-segment.mp3",
          "57_Tongue_click_1419745-17-19-segment.mp3",
          "58_Tongue_click_1474184-4.7-5.9-segment.mp3",
          "59_Tongue_click_1474192-0-1.6-segment.mp3",
          "60_Tongue_click_1477051-17.9-20.3-segment.mp3"
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[1].sounds[5][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.human_interaction}/Whistle`,
        items: [
          "Whistle_sample1-1.mp3",
          "Whistle_sample2.wav",
          "Whistle_sample3.wav",
          "Whistle_sample4.wav",
          "Whistle_sample5.wav",
        ],
      },
    ],
  },
  {
    display_name: SoundEventDetection.Classes[2].category,
    directory_path: SOUND_DIRECTORY_PATH.human_status,
    sub_directories: [
      {
        sub_display_name: SoundEventDetection.Classes[2].sounds[0][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.human_status}/Cough`,
        items: [
          "Cough_sample1.wav",
          "Cough_sample2-1.mp3",
          "Cough_sample3-1.mp3",
          "Cough_sample4.wav",
          "Cough_sample5.wav",
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[2].sounds[1][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.human_status}/Hiccup`,
        items: [
          "31_Hiccup_503107_cut.mp3",
          "32_Hiccup_503469-cut.mp3",
          "33_Hiccup_507016-cut.mp3",
          "34_Hiccup_508837-cut.mp3",
          "35_Hiccup_511971-cut.mp3"
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[2].sounds[2][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.human_status}/Laughter`,
        items: [
          "Laughter_sample1-1.mp3",
          "Laughter_sample2.wav",
          "Laughter_sample3-1.mp3",
          "Laughter_sample4.wav",
          "Laughter_sample5.wav",
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[2].sounds[3][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.human_status}/Sigh`,
        items: [
          "Sigh_sample1.wav",
          "Sigh_sample2-1.mp3",
          "Sigh_sample3.wav",
          "Sigh_sample4.wav",
          "Sigh_sample5.wav",
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[2].sounds[4][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.human_status}/Sneeze`,
        items: [
          "Sneeze_sample1.wav",
          "Sneeze_sample2.wav",
          "Sneeze_sample3.wav",
          "Sneeze_sample4.wav",
          "Sneeze_sample5-1.mp3",
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[2].sounds[5][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.human_status}/Snore`,
        items: [
          "Snore_sample1.wav",
          "Snore_sample2.wav",
          "Snore_sample3.wav",
          "Snore_sample4.wav",
          "Snore_sample5.wav",
        ],
      },
    ],
  },
  {
    display_name: SoundEventDetection.Classes[3].category,
    directory_path: SOUND_DIRECTORY_PATH.home_context,
    sub_directories: [
      {
        sub_display_name: SoundEventDetection.Classes[3].sounds[0][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.home_context}/appliance_alarm`,
        items: [
          "23441__percy-duke__cordless-phone-beep-1.mp3",
          "277510__kwahmah-02__fridge-door-alarm-help-read-description.mp3",
          "327057__reydros__microwave-start-noise-beeps.mp3",
          "364851__rutgermuller__japan-washing-machine-beeps-and-air-exhaust.mp3",
          "595829__binxa__setting-up-washing-machine.mp3",
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[3].sounds[1][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.home_context}/Baby_cry`,
        items: [
          "Baby_cry_sample1.wav",
          "Baby_cry_sample2.wav",
          "Baby_cry_sample3.wav",
          "Baby_cry_sample4.wav",
          "Baby_cry_sample5.wav",
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[3].sounds[2][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.home_context}/Cat_meow`,
        items: [
          "11_Cat_meow_1188818-0-9-segment.mp3",
          "12_Cat_meow_1188821-50.6-52.2-segment.mp3",
          "13_Cat_meow_1215349-95-97-segment.mp3",
          "14_Cat_meow_1215364-7-8.6-segment.mp3",
          "15_Cat_meow_1215367-28.69-30.37-segment.mp3"
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[3].sounds[3][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.home_context}/Dog_bark`,
        items: [
          "Dog_bark_sample1.wav",
          "Dog_bark_sample2.wav",
          "Dog_bark_sample3.wav",
          "Dog_bark_sample4.wav",
          "Dog_bark_sample5.wav",
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[3].sounds[4][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.home_context}/doorbell`,
        items: [
          "69384__guitarguy1985__doorbell1-1.mp3",
          "205949__ryding__doorbell-1.mp3",
          "218317__splicesound__ring-nyc-apartment-doorbell-neighbor-dog-barks.mp3",
          "275072__kwahmah-02__doorbell-a.mp3",
          "614147__soundslikewillem__ding-dong.mp3",
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[3].sounds[5][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.home_context}/water_run`,
        items: [
          "339324__inspectorj__stream-water-c-1.mp3",
          "342419__ivolipa__waterflow-iii.mp3",
          "417737__inspectorj__water-pour-sink-a.mp3",
          "420224__14fpanska-nemec-petr__37-15-waterflow-v2.mp3",
          "437508__tianve8__watertap-1.mp3",
        ],
      },
    ],
  },
  {
    display_name: SoundEventDetection.Classes[4].category,
    directory_path: SOUND_DIRECTORY_PATH.human_voice,
    sub_directories: [
      {
        sub_display_name: SoundEventDetection.Classes[4].sounds[0][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.human_voice}/Female_speech`,
        items: [
          "21_Female_speech_546777__funnyvoices__happy-new-year.wav",
          "22_Female_speech_213284__aderumoro__hello-2-female-friendly.mp3",
          "23_Female_speech_610527__sample-me__dream-female-spoken-word.wav",
          "24_Female_speech_403769__davestalker__women-talking-about-girls-night-out.mp3",
          "25_Female_speech_62950__clivew__blind-lady-more-often.wav"
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[4].sounds[1][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.human_voice}/Humming`,
        items: [
          "36_Humming_1737079-5.4-8.8-segment.mp3",
          "37_Humming_1611325-0-4-segment.mp3",
          "38_Humming_1611444-1.4-8.3-segment.mp3",
          "39_Humming_1611713-3.9-9.5-segment.mp3",
          "40_Humming_1723772-1.4-9.4-segment.mp3"
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[4].sounds[2][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.human_voice}/Male_speech`,
        items: [
          "41_Male_speech_515242__danlucaz__hey-can-you-hear-me-i-m-in-trouble.mp3",
          "42_Male_speech_476647__ffmmendoza90__hello-man.wav",
          "43_Male_speech_131651__ecfike__grumpy-old-man-cut.mp3",
          "44_Male_speech_371550__iceofdoom__if-you-know-what-i-mean-haha-male.wav",
          "45_Male_speech_401794__sean-te0__we-ll-eat-pizzas.mp3"
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[4].sounds[3][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.human_voice}/Whisper`,
        items: [
          "61_Whisper_623969-cut.mp3",
          "62_Whisper_364972__balloonhead__whispered-hello-goodbye-german-english (1).mp3",
          "63_Whisper_508610-4.8-6.9-segment.mp3",
          "64_Whisper_545871-0-3.5-segment.mp3",
          "65_Whisper_623910-0-3.25-segment.mp3"
        ],
      },
    ],
  },
  {
    display_name: SoundEventDetection.Classes[5].category,
    directory_path: SOUND_DIRECTORY_PATH.traffic,
    sub_directories: [
      {
        sub_display_name: "Car crash",
        sub_directory_path: `${SOUND_DIRECTORY_PATH.traffic}/Car_crash`,
        items: [
          "Car_crash1.mp3",
          "Car_crash2.mp3",
          "Car_crash3.mp3",
          "Car_crash4-1.mp3",
          "Car_crash5.mp3"
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[5].sounds[0][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.traffic}/Car_horn`,
        items: [
          "06_Car_horn_105790-0-2.mp3",
          "07_Car_horn_105807-0-1.mp3",
          "08_Car_horn_105816.mp3",
          "09_Car_horn_536068-cut.mp3",
          "10_Car_horn_105802.mp3"
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[5].sounds[1][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.traffic}/Loud_engine_acceleration`,
        items: [
          "Loud_engine_acceleration1.mp3",
          "Loud_engine_acceleration2.mp3",
          "Loud_engine_acceleration3-1.mp3",
          "Loud_engine_acceleration4.mp3",
          "Loud_engine_acceleration5.mp3",
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[5].sounds[2][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.traffic}/Tire_squeal`,
        items: [
          "Tire_squeal1.mp3",
          "Tire_squeal2.mp3",
          "Tire_squeal3.mp3",
          "Tire_squeal4-1.mp3",
          "Tire_squeal5-1.mp3",
        ],
      },
    ],
  },
  {
    display_name: SoundEventDetection.Classes[6].category,
    directory_path: SOUND_DIRECTORY_PATH.traffic,
    sub_directories: [
      {
        sub_display_name: SoundEventDetection.Classes[6].sounds[0][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.etc}/Applause`,
        items: [
          "01_Applause_484188-0-7-segment.mp3",
          "02_Applause_488567-0.2-7.5-segment.mp3",
          "03_Applause_485202-0-9-segment_loud.mp3",
          "04_Applause_484863-0-13.mp3",
          "05_Applause_484465-0-8-segment_small group.mp3"
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[6].sounds[1][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.etc}/Crowd`,
        items: [
          "16_Crowd_1485768-0-10-segment.mp3",
          "17_Crowd_210611__mefrancis13__large-crowded-room-voices-cut.mp3",
          "18_Crowd_243373__johnsonbrandediting__small-crowd-pre-concert-talking-party-bar-walla-talking.mp3",
          "19_Crowd_192930__klankbeeld__high-school-canteen-cut.mp3",
          "20_Crowd_641793__dibko__sale-of-bus-tickets-cut.mp3"
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[6].sounds[2][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.etc}/Guitar`,
        items: [
          "26_Guitar_484334-0-3.6-segment.mp3",
          "27_Guitar_476233__nomerodin1__acoustic-guitar-am.wav",
          "28_Guitar_120980__thirsk__110-acoustic-grunge.mp3",
          "29_Guitar_211964__maurolupo__acoustic-guitar-rythm.mp3",
          "30_Guitar_488350-0.5-5.4-segment_playing no bgm.mp3"
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[6].sounds[3][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.etc}/Piano`,
        items: [
          "46_Piano_146165__amandajbono__piano.mp3",
          "47_Piano_545925__sly-r__lounge.wav",
          "48_Piano_1906__nicstage__pianoloop.wav",
          "49_Piano_545926__sly-r__sudden-memory.wav",
          "50_Piano_js.mp3"
        ],
      },
      {
        sub_display_name: SoundEventDetection.Classes[6].sounds[4][0],
        sub_directory_path: `${SOUND_DIRECTORY_PATH.etc}/Thud`,
        items: [
          "51_Thud_524004-3-4-segment.mp3",
          "52_Thud_1435770-116.9-117.9-segment.mp3",
          "53_Thud_1857187-160-161-segment.mp3",
          "54_Thud_1485318-2.1-3.3-segment.mp3",
          "55_Thud_1487393-42-44-segment.mp3"
        ],
      },
    ],
  },
  
];
