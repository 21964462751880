import styled from 'styled-components'
import { Typography } from '../constant/GlobalTypography'
import { DefaultInnerwrap } from '../constant/GlobalStyles'

const TitleArea = styled.div`
  margin-bottom: 100px;
`
const Title = styled.h1`
  ${Typography.h1};
  margin: 180px 0;
  text-align: center;
`
const Date = styled.p`
  ${Typography.h6};
`
const Description = styled.p`
  ${Typography.body1};
`


const TermsTop = (props: Props) => {
  const {pageData} = props

  return(
    <TitleArea>
      <DefaultInnerwrap>
        <Title>{pageData.title}</Title>
        <Date>{pageData.date}</Date>
        <Description>{pageData.description}</Description>
      </DefaultInnerwrap>
    </TitleArea>
  )

}

type Props = {
  pageData: any
}

export default TermsTop;