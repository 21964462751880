import axios from "axios";

const { REACT_APP_API_URL: apiURL } = process.env;

const API_BASE_URL = apiURL;
axios.defaults.baseURL = API_BASE_URL;

export const EventCustom = async (formData: any) => {
  return axios.post("/event_custom", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const MusicContents = async (formData: any) => {
  return axios.post("/music_contents", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const MusicId = async (formData: any) => {
  return axios.post("/music_id", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const MusicRecommend = async (formData: any) => {
  return axios.post("/music_recommend", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const SpeakerGender = async (formData: any) => {
  return axios.post("/speaker_gender", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const SpeakerId = async (formData: any) => {
  return axios.post("/speaker_id", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
