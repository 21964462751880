import styled from 'styled-components'
import { Typography } from '../constant/GlobalTypography'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const borderMargin = '16px'

const SongItemWrap = styled.li<{isPlaying: boolean}>`
  position: relative;
  height: 78px;
  display: flex;
  align-items: center;
  background-color: ${props => props.isPlaying ? 'rgba(190, 196, 201, 0.2)' : ''};
  &:hover {
    cursor: pointer;
    background-color: rgba(190, 196, 201, 0.2);
  }
  &:hover #play_button {
    border-color: #131313;
    color: #131313;
  }
`

const SongItemInnerWrap = styled.div`
  position: relative;
  min-height: 50px;
  width: 100%;
  margin: 15px ${borderMargin};
  display: flex;
  align-items: center;
`

const SongInfo = styled.div`
  position: relative;
  height: 100%;
  margin-left: 20px;
  flex-grow: 1;
` 

const SongName = styled.div`
  ${Typography.body2};
  font-weight: 600;
  font-size:  16px;
  line-height: 28px;
`

const SongAuthor = styled.div`
  ${Typography.body2};
  font-weight: 400;
  font-size: 13px;
  line-height: 16.9px;
  color: #B9B9B9;
`

const PlayButton = styled.div<{isPlaying: boolean}>`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #B9B9B9;
  border-color: ${props => props.isPlaying ? '#131313' : '#B9B9B9'};
  color: ${props => props.isPlaying ? '#131313' : '#B9B9B9'};
`

const SongItem = (props: Props) => {

  const { songData, songId, isPlaying, playSong, setIsPlaying } = props

  const songControl = () => {
    if(isPlaying) {
      setIsPlaying(false)
    } else {
      playSong()
    }
  }

  return (
    <SongItemWrap isPlaying={isPlaying} onClick={songControl}>
      <SongItemInnerWrap>
        <PlayButton id='play_button' isPlaying={isPlaying}>
          {isPlaying ? <PauseIcon/> : <PlayArrowIcon/>}
        </PlayButton>
        <SongInfo>
          <SongName>{songData.songName}</SongName>
          <SongAuthor>{songData.songAuthor}</SongAuthor>
        </SongInfo>
      </SongItemInnerWrap>
    </SongItemWrap>
  )
}

type Props = {
  songData: {
    songName: string, 
    songUrl: string, 
    songAuthor: string
  }, 
  songId: number, 
  isPlaying: boolean,
  playSong: any, 
  setIsPlaying: any
}

export default SongItem