import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { DefaultInnerwrap } from '../constant/GlobalStyles'
import { Typography } from '../constant/GlobalTypography'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'

const AccordionBox = styled.dl`
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  margin-bottom: 35px;
  &:last-child {
    margin-bottom: 0;
  }
`
const Title = styled.dt<{ data: string, name: any, value: number }>`
  cursor: pointer;
  ${Typography.h4 as any};
  padding: 30px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & + dd {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease;
    -moz-transition: max-height 0.5s ease;
    -webkit-transition: max-height 0.5s ease;
    -o-transition: max-height 0.5s ease;
  }
  > svg {
    transition: transform 0.5s ease;
    -moz-transition: transform 0.5s ease;
    -webkit-transition: transform 0.5s ease;
    -o-transition: transform 0.5s ease;
  }
  &[data='open'] {
    > svg {
      transform: rotate(180deg);
    }
  }
  &[data='open'] + dd {
    max-height: 1000px;
  }
  &[data='close'] + dd {
    max-height: 0;
  }
`
const Contents = styled.dd`
  ${Typography.body2 as any};
  box-sizing: border-box;
  margin: 0 60px;
  > p {
    padding-bottom: 15px;
  }
  > p:last-child {
    padding-bottom: 45px;
  }
`

const Accordion = (props: Props) => {
  const {pageData, className} = props

  const [AccordionState, setAccordionState] = useState(null);


  useEffect(()=> {
    const buttonArray = document.getElementsByName('button');

    for(let i = 0; i < buttonArray.length; i++) {
      if(i == AccordionState) {
        buttonArray[i].setAttribute('data', 'open')
      } else {
          buttonArray[i].setAttribute('data', 'close')
        }
    }
  },[AccordionState])


  const onlyOpen = (currentbutton: any) => {
    const data = currentbutton.getAttribute('data')
    const value = currentbutton.getAttribute('value')
    if(data === 'close') {
      setAccordionState(value);
    } else {
      currentbutton.setAttribute('data', 'close')
      setAccordionState(null);
    }
  }

  const handleClick = (event: any) => {
    event.preventDefault();
    const button = event.currentTarget;
    onlyOpen(button)
  };
  
  return(
    <>
      <DefaultInnerwrap className={className}>
        {
          pageData.map((item:any, index:number) => (
            <AccordionBox key={index}>
              <Title onClick={handleClick} data={'close'} name={'button'} value={index} ><span>{item.title}</span><KeyboardArrowDownOutlinedIcon/></Title>
              <Contents>
                {item.contents.map((conts:any) => (
                  <p>{conts}</p>
                ))}
              </Contents>
            </AccordionBox>
          ))
        }
      </DefaultInnerwrap>
    </>
  )

}

type Props = {
  pageData: any
  className?: any
}

export default Accordion;